import React, { useEffect, useState } from "react";
import Table from "./Table";
import axios from 'axios'
import { Box, Button, Container, TextField, Typography } from "@material-ui/core";
import { createOrder } from "../globals/fiat_payment";
import QRCode from "react-qr-code";

export function ReceiveToken({walletAddress}) {
    const [amount, setAmount] = useState();
    useEffect(() => {
        
    }, [walletAddress])

    return (
        <Container maxWidth="xs">
        <Box style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            marginTop: '20px'}}>
            <Typography variant="h6">Receive Token</Typography>
            <TextField
                variant="outlined"
                margin="normal"
                required
                size="small"
                fullWidth
                name="txtamount"
                label="Share this link to receive tokens"
                type="numeric"
                id="txtamount"
                value={'https://wallet.newrl.net/transfer?destination_address=' + walletAddress}
                onFocus={event => {
                    event.target.select();
                }}
            />
            <Box style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
            }}>
                <QRCode value={'https://wallet.newrl.net/transfer?destination_address=' + walletAddress} />
            </Box>
            {/* <Button
                variant="contained"
                onClick={() => createOrder(walletAddress, amount)}
                size="small"
                color="primary"
                fullWidth
                >
                Copy Receive Link
            </Button> */}
        </Box>
        </Container>
    )
}