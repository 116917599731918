import React, { useState, useEffect } from 'react';
import { Fab, Button, Typography, Grid, Container, Box, TextField, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Paper } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { getBlockchainNodeUrl, FAUCET_URL, NWRL_DECIMAL_MULTIPLIER } from '../globals/const';
import { isValidWalletAddress } from '../globals/utils';
import { useLocation } from "react-router-dom";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  mainContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    flexGrow: 1,
  },
  row: {
    item: true,
    container: true,
    spacing: 1,
    justify: 'center',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
  fabRow: {},
  fundSelectorRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonRow: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-around',
  }
}));


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Faucet() {
  let urlQuery = useQuery();
  const walletToFundFromUrl = urlQuery.get('wallet_to_fund')
  const qtyFromUrl = urlQuery.get('qty')
  const classes = useStyles();
  const [wallet, setWallet] = useState({});
  const [transaction, setTransaction] = useState("")
  const [walletToFund, setWalletToFund] = useState(walletToFundFromUrl)
  const [amountToFund, setAmountToFund] = useState(qtyFromUrl)
  const [isWalletActive, setIsWalletActive] = useState(false);
  const maxQuantity = 10000;


  const fundWallet = async () => {
    if (!isValidWalletAddress(walletToFund)) {
      alert('Invalid destination address')
      return
    }
    if (!amountToFund) {
      alert('Invalid amount')
      return
    }
    if (!maxQuantity || amountToFund > maxQuantity) {
        alert('Quantity exceeds max quantity')
        return
    }
    let res = await axios.get(
      FAUCET_URL + `?wallet=${walletToFund}&amount=${parseInt(amountToFund * NWRL_DECIMAL_MULTIPLIER)}`)
    console.log(res)
    if (res.status === 200 && res.data?.response?.valid) {
      alert('Faucet transaction successfully sent to blockchain')
      setAmountToFund(undefined)
    } else {
      alert('Failed to submit fund transaction')
      // alert(JSON.stringify(res.data))
    }
  }

  // useEffect(() => {
  //   getWalletFromDB((wallet) => { if (wallet) setWallet(wallet) });

  // }, []);

  // useEffect(() => {

  // }, [wallet]);

  return (
    <Container maxWidth="xs">
      <div className={classes.mainContent}>
        <Grid
          container
          // spacing={1}
          justify="center"
          direction="column"
          style={{ marginTop: '10px' }}
        >
          {/* FundSelector */}
          <Grid container item>
            <Grid item xs>

              <Box style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                marginTop: '20px',
              }}>
                <Typography variant="h4" style={{ marginTop: '10px' }}>Testnet Faucet:</Typography>
                <TextField
                    // variant="outlined"
                    margin="normal"
                    required
                    // size="small"
                    fullWidth
                    name="txtamount"
                    label="Wallet Address"
                    id="txtamount"
                    value={walletToFund}
                    helperText={!isValidWalletAddress(walletToFund) && 'Invalid address'}
                    onChange={event => setWalletToFund (event.target.value)}
                />
                <TextField
                    // variant="outlined"
                    margin="normal"
                    required
                    // size="small"
                    fullWidth
                    name="txtamount"
                    label="Quantity"
                    helperText={maxQuantity && ('Max quantity ' + maxQuantity)}
                    type="numeric"
                    id="txtamount"
                    value={amountToFund}
                    onChange={event => setAmountToFund (event.target.value)}
                />
                <Button
                  variant="contained"
                  onClick={fundWallet}
                  size="small"
                  color="primary"
                >
                  Fund</Button>
                  <Button
                  variant="outlined"
                  onClick={() => window.history.back()}
                  size="small"
                  color="primary"
                  style={{marginTop: 10}}
                >
                  Back</Button>

              </Box>
                

            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
