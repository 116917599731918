import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from '../components/Copyright';

import {useHistory, Link} from 'react-router-dom';
import {useState} from 'react';
import axios from 'axios';
import {SERVER_BASE_ADDRESS} from '../globals/const';

const useStyles = makeStyles (theme => ({
  paper: {
    marginTop: theme.spacing (8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing (1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing (3),
  },
  submit: {
    margin: theme.spacing (3, 0, 2),
  },
}));

export default function Register () {
  const classes = useStyles ();
  const history = useHistory ();

  const [creds, setCreds] = useState ({});

  function setCredField (key, value) {
    let _creds = creds;
    creds[key] = value;
    setCreds (_creds);
  }

  function submitHandler (event) {
    event.preventDefault ();

    creds.username = creds.email;
    axios.defaults.headers = {'Content-Type': 'application/json'};
    axios
      .post (SERVER_BASE_ADDRESS + '/auth/register', creds)
      .then (res => {
        window.localStorage.setItem ('username', res.data.user.username);
        window.localStorage.setItem ('userid', res.data.user.id);
        window.localStorage.setItem ('token', res.data.token);
        window.localStorage.setItem ('isNewUser', res.data.isNewUser);
        history.push ('.');
        // this.setState ({isLoggedIn: true, isNewUser: res.data.isNewUser});
      })
      .catch (e => {
        // this.setState ({errorText: 'Authentication Failure'});
        alert ('Unable to register. Email already in use.');
      });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} onSubmit={submitHandler}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                onChange={event =>
                  setCredField ('first_name', event.target.value)}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                onChange={event =>
                  setCredField ('last_name', event.target.value)}
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                // required
                fullWidth
                // id="email"
                label="Email Address"
                onChange={event => setCredField ('email', event.target.value)}
                // name="email"
                // autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                // name="password"
                label="Password"
                type="password"
                onChange={event =>
                  setCredField ('password', event.target.value)}
                // id="password"
                // autoComplete="off"
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid> */}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justify="center">
            <Grid item>
              <Link to="login">Already have an account? Sign in</Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
