//Global constants in this file

// export const HOME_PAGE = process.env.PUBLIC_URL;
export const HOME_PAGE = '';

export const ASQI_COLOR = "#0e4476";
//Dev
var _SERVER_BASE_ADDRESS = 'http://localhost:8000';
// var _SERVER_BASE_ADDRESS = 'http://192.168.10.38:8000';
// var _SERVER_BASE_ADDRESS = 'https://asqisys.com/backend';
var _GOOGLE_OAUTH_CLIENT_ID = '978566420462-jsrnp54ovfn94q05551b3ge73q413h6m.apps.googleusercontent.com'
var _RZP_KEY = 'rzp_test_lIPFFCrCh8ljm1'

let _NEWRL_PAY_SERVICE = 'http://127.0.0.1:8000';
if(process.env.NODE_ENV === 'production'){
    // Prod
    _SERVER_BASE_ADDRESS = '/backend';
    // _SERVER_BASE_ADDRESS = '/staging-backend';
    _GOOGLE_OAUTH_CLIENT_ID = '978566420462-6g0ase60jqiqnta03q6aaoirnovk2e52.apps.googleusercontent.com'
    _RZP_KEY = 'rzp_live_SvgK1UWUY0ad5q'

    _NEWRL_PAY_SERVICE = 'https://newrl-pay-api.newrl.net'
}

// _SERVER_BASE_ADDRESS = 'https://asqisys.com/retail-backend';

export const SERVER_BASE_ADDRESS = _SERVER_BASE_ADDRESS;
export const GOOGLE_OAUTH_CLIENT_ID = _GOOGLE_OAUTH_CLIENT_ID;
export const RZP_KEY = _RZP_KEY;

// export const getBlockchainNodeUrl() = 'https://devnetapi.newrl.net';
// export let getBlockchainNodeUrl() = 'http://archive1-mainnet.newrl.net:8456';

export const NEWRL_PAY_SERVICE = _NEWRL_PAY_SERVICE;
export const FAUCET_URL = 'https://gsd8hwxzw9.execute-api.ap-south-1.amazonaws.com/default/NWRLFaucet'
// export const NEWRL_PAY_SERVICE = 'http://127.0.0.1:8000'

export const NWRL_DECIMAL_MULTIPLIER = 1000000;
// export const getBlockchainPort() = 8421;
// export const NWRL_SCAN_BACKEND = 'https://newrl-testnet-scan-backend.herokuapp.com'

export const changeBlockChain = (chain) => {
    if (chain === 'Mainnet') {
        // getBlockchainNodeUrl() = 'http://archive1-mainnet.newrl.net:8456';
        localStorage.setItem('blockchain', 'Mainnet')
    } else {
        // getBlockchainNodeUrl() = 'https://devnetapi.newrl.net';
        localStorage.setItem('blockchain', 'Testnet')
    }
    // window.location.reload()
}

export const BLOCKCHAINS = {
    'Mainnet': {
        node: 'https://mainnetgw.newrl.net',
        scanner: 'https://mainnet-scanner-api.newrl.net',
        port: 8456,
    },
    'Testnet': {
        // node: 'https://devnetapi.newrl.net',
        // scanner: 'https://newrl-testnet-scan-backend.herokuapp.com',
        node: 'https://lakeshore-testnetgw.newrl.net',
        scanner: 'https://newrl-lakeshore-test-backend.herokuapp.com',
        port: 8421,
    }
}

export const getBlockchainNodeUrl = () => {
    let chain = localStorage.getItem('blockchain');
    if (!chain || chain === null) {
        chain = 'Mainnet'
        localStorage.setItem('blockchain', 'Mainnet')
    }
    
    return BLOCKCHAINS[chain]['node'];
}

export const getBlockchain = () => {
    let chain = localStorage.getItem('blockchain');
    if (!chain || chain === null) {
        chain = 'Mainnet'
        localStorage.setItem('blockchain', 'Mainnet')
    }
    return chain;
}

export const getBlockchainPort = () => {
    let chain = localStorage.getItem('blockchain');
    if (!chain || chain === null) {
        chain = 'Mainnet'
        localStorage.setItem('blockchain', 'Mainnet')
    }
    
    return BLOCKCHAINS[chain]['port'];
}

export const getBlockchainScanner = () => {
    let chain = localStorage.getItem('blockchain');
    if (!chain || chain === null) {
        chain = 'Mainnet'
        localStorage.setItem('blockchain', 'Mainnet')
    }
    
    return BLOCKCHAINS[chain]['scanner'];
}

export const getTransactionToSignFromLocal = () => {
    let txn = localStorage.getItem('transactionToSign');
    try {
        txn = txn;
        return txn;
    } catch {
        return ''
    }
}

export const NUSDC_TOKEN_CODE = 'NUSDC';
export const OTC_FEE_PAYER = '0x3f6f782be887b1e565028d5d53d8c79c9222b745';