import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { isLoggedIn } from "../globals/utils";
import { useHistory, useLocation } from "react-router-dom";
import { Drawer, Divider } from "@material-ui/core";
import AccountBalanceWalletOutlined from "@material-ui/icons/AccountBalanceWalletOutlined";
import PeopleOutlineOutlined from "@material-ui/icons/PeopleOutlineOutlined";
import PlayArrowOutlined from "@material-ui/icons/PlayArrowOutlined";
import VpnKey from "@material-ui/icons/VpnKey";
import BusinessOutlined from "@material-ui/icons/BusinessOutlined";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import SwapHorizOutlined from "@material-ui/icons/SwapHorizOutlined";
import StarOutline from "@material-ui/icons/StarOutline";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
// import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
// import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";

import { changeBlockChain, getBlockchain, HOME_PAGE } from "../globals/const";
import { logout } from "../globals/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    width: theme.spacing(36),
  },
  drawerOpen: {
    width: 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

export default function HawkEyeAppBar() {
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [blockchain, setBlockchain] = useState(getBlockchain());

  

  if (!isLoggedIn()) {
    let next = history.location.pathname + history.location.search;
    console.log(next);
    next = next.replace("next=/login?", "");
    console.log(next);
    history.push("/login?next=" + next);
  }

  const onChainToggle = (val) => {
    const chain = val ? 'Mainnet' : 'Testnet';
    setBlockchain(chain)
    // changeBlockChain -> window reload removed
    changeBlockChain(chain)
  }

  return (
    <div className={classes.root}>
      <Fragment>
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={setDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Newrl Wallet
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
            <FormGroup>
              <FormControlLabel control={<Switch checked={blockchain === 'Mainnet'} onChange={(event) => onChainToggle(event.target.checked)} />} label={blockchain} />
            </FormGroup>
          </Toolbar>
        </AppBar>

        <Drawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          classes={{ paper: classes.drawerPaper }}
        >
          <Grid item container>
            <Grid item container spacing={2} alignItems="center">
              <Grid item style={{ padding: "27px" }}>
                <img src={HOME_PAGE + "/newrl_logo.png"} width="64px" alt="" />
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  className={classes.title}
                  style={{ color: "#616161" }}
                >
                  Newrl Wallet
                </Typography>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid xs item>
                <Divider />
              </Grid>
            </Grid>

            <Grid item container>
              <Grid xs item>
                <List>
                  <ListItem
                    button
                    onClick={() => {
                      history.push(HOME_PAGE + "/wallet");
                      setDrawerOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <AccountBalanceWalletOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Wallet" />
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => {
                      history.push(HOME_PAGE + "/trustnet");
                      setDrawerOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <PeopleOutlineOutlined />
                    </ListItemIcon>
                    <ListItemText primary="TrustNet" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      history.push(HOME_PAGE + "/sign-transaction");
                      setDrawerOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <VpnKey />
                    </ListItemIcon>
                    <ListItemText primary="Sign Transaction" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      history.push(HOME_PAGE + "/run-node");
                      setDrawerOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <PlayArrowOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Validator Management" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      history.push(HOME_PAGE + "/otc-market/trade-list");
                      setDrawerOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <PlayArrowOutlined />
                    </ListItemIcon>
                    <ListItemText primary="OTC Market(Beta)" />
                  </ListItem>
                  {getBlockchain() === 'Testnet' && <ListItem
                    button
                    onClick={() => {
                      history.push(HOME_PAGE + "/faucet");
                      // window.open(HOME_PAGE + "/faucet");
                      setDrawerOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <StarOutline />
                    </ListItemIcon>
                    <ListItemText primary="Faucet" />
                  </ListItem>}
                  <ListItem
                    button
                    onClick={() => {
                      history.push(HOME_PAGE + "/daos");
                      setDrawerOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <BusinessOutlined />
                    </ListItemIcon>
                    <ListItemText primary="DAOs" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      history.push(HOME_PAGE + "/dex");
                      setDrawerOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <SwapHorizOutlined />
                    </ListItemIcon>
                    <ListItemText primary="DEX" />
                  </ListItem>
                  <Divider />

                  {/* <ListItem button onClick={() => {window.open('mailto:hawkeye@asqi.in?subject=HawkEye help')}}>
                    <ListItemIcon><HelpOutlineOutlinedIcon /></ListItemIcon>
                    <ListItemText primary="Help" />
                  </ListItem> */}
                  <ListItem
                    button
                    onClick={() => {
                      window.open(
                        "mailto:connect@newrl.net?subject=Newrl Wallet: "
                      );
                    }}
                  >
                    <ListItemIcon>
                      <FeedbackOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Feedback" />
                  </ListItem>

                  
                  {/* <ListItem button onClick={() => {window.open('mailto:hawkeye@asqi.in?subject=HawkEye feedback')}}>
                    <ListItemIcon><InfoOutlinedIcon /></ListItemIcon>
                    <ListItemText primary="About" />
                  </ListItem> */}
                  <Divider />

                  <ListItem
                    button
                    onClick={() => {
                      logout();
                    }}
                  >
                    <ListItemIcon>
                      <ExitToAppOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItem>


                </List>
              </Grid>
            </Grid>
          </Grid>
        </Drawer>
      </Fragment>
    </div>
  );
}
