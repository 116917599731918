import React, { useEffect, useState } from "react";
import axios from 'axios'
import { getBlockchainNodeUrl, NEWRL_PAY_SERVICE, NUSDC_TOKEN_CODE, OTC_FEE_PAYER } from "../globals/const";
import { Box, Button, TextField, Typography, FormControl, InputLabel, MenuItem, Select, Container } from "@material-ui/core";
import { FormControlLabel, Radio, RadioGroup, FormLabel } from "@material-ui/core";
import { triggerNUSDCBuy } from "../globals/fiat_payment";
import { getWalletFromDB } from "../globals/db";
import { getDecimal } from "../globals/utils";
import { useLocation } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function OTCMarket() {
    let urlQuery = useQuery();

    const [quantity, setQuantity] = useState();
    const [destinationAddress, setDestinationAddress] = useState(urlQuery.get('destination_address'));
    const [token, setToken] = useState('NWRL');
    const [baseToken, setBaseToken] = useState(NUSDC_TOKEN_CODE);
    const [wallet, setWallet] = useState({});
    const [maxQuantity, setMaxQuantity] = useState(0);
    const [walletBalances, setWalletBalances] = useState([]);
    const [tokenList, setTokenList] = useState(['NWRL']);
    const [baseTokenList, setBaseTokenList] = useState([NUSDC_TOKEN_CODE]);
    const [tradeType, setTradeType] = useState('Buy');
    const [price, setPrice] = useState(0.05);
    const [tokensRequired, setTokensRequired] = useState('');
    const [nusdcToBuy, setNUSDCToBuy] = useState(0);
    
    // useEffect(() => {
    //     console.log(balances)
    //     if (balances && balances.length > 0) {
    //         setWalletBalances(balances)
    //     }
    // }, [balances])

    const setBounds = (_walletBalances) => {
        // setMaxQuantity(walletBalances.find((b) => b.token_code === token)?.balance)
        const nwrlBalance = _walletBalances.find((b) => b.token_code === 'NWRL')?.balance / getDecimal('NWRL');
        const nusdcBalance = _walletBalances.find((b) => b.token_code === NUSDC_TOKEN_CODE)?.balance / getDecimal(NUSDC_TOKEN_CODE);
        console.log(nwrlBalance, nusdcBalance)
        if (tradeType === 'Buy') {
          setMaxQuantity(nusdcBalance ? nusdcBalance / price : 0)
        } else {
            if (isNaN(nwrlBalance)) {
                setMaxQuantity(0)
            } else {
                setMaxQuantity(nwrlBalance)
            }
        }
        // console.log(nwrlBalance, tradeType)
    }

    useEffect(() => {
        setBounds(walletBalances)
    }, [tradeType, price])

    useEffect(() => {
        getWalletFromDB((wallet) => { if (wallet) setWallet(wallet) });
    
      }, []);
    
    useEffect(() => {
        if(isNaN(quantity) || isNaN(price)) {
            setTokensRequired('')
            return;
        }
        let nusdcBalance = walletBalances.find((b) => b.token_code === NUSDC_TOKEN_CODE)?.balance / getDecimal(NUSDC_TOKEN_CODE);
        nusdcBalance = isNaN(nusdcBalance) ? 0 : nusdcBalance;
        if (tradeType === 'Buy') {
            setTokensRequired('Base tokens Required: ' + (price * quantity).toFixed(6) + ' NUSDC. Your balance is: ' + nusdcBalance);
            setNUSDCToBuy(Math.max(0, price * quantity - nusdcBalance))
        } else {
            let tokenNeeded = parseInt(quantity)
            setTokensRequired('Tokens Required: '+ tokenNeeded + ' NWRL. Your balance is: ' + maxQuantity)
        }
    }, [tradeType, price, quantity]);
    
    useEffect(() => {
        // if (!balances) {
            axios.get(getBlockchainNodeUrl() + '/get-balances?balance_type=ALL_TOKENS_IN_WALLET&wallet_address=' + wallet.address)
            .then(res => res.data.balance)
            .then(data => {
                console.log(data)
                // setToken(data[0]?.token_code)
                setWalletBalances(data)
                setBounds(data)
                const baseTokenBalance = walletBalances.find((b) => b.token_code === baseToken)?.balance;
                if (!isNaN(baseTokenBalance))
                    setMaxQuantity(baseTokenBalance / getDecimal(baseToken))
            })
        // }
    }, [wallet])

    const submitTrade = async () => {
        console.log(maxQuantity,  quantity)
        if (!maxQuantity || quantity > maxQuantity) {
            alert('Quantity exceeds max quantity')
            return
        }
        if (quantity === 0 || !quantity) {
            alert('Quantity should be non zero')
            return
        }
        if (token in [null, undefined, '']) {
            alert('Invalid token')
            return
        }
        console.log(token, baseToken, quantity, price, tradeType)

        const asset1_code = tradeType === 'Buy' ? baseToken : token;
        const asset2_code = tradeType === 'Buy' ? token : baseToken;
        let asset1_qty = tradeType === 'Buy' ? price * quantity : quantity;
        let asset2_qty = tradeType === 'Buy' ? quantity : price * quantity;

        asset1_qty = parseInt(asset1_qty * getDecimal(asset1_code));
        asset2_qty = parseInt(asset2_qty * getDecimal(asset2_code));
        
        let res = await axios.post(
            getBlockchainNodeUrl() + '/add-transfer',
            {
                transfer_type: 4,
                asset1_code: asset1_code,
                asset2_code: asset2_code,
                wallet1_address: wallet.address,
                wallet2_address: null,
                asset1_qty: asset1_qty,
                asset2_qty: asset2_qty,
            }
        );
        let transaction_data = res.data;
        transaction_data['transaction']['fee'] = 1000000;
        transaction_data['transaction']['fee_payer'] = OTC_FEE_PAYER;
        
        res = await axios.post(
            getBlockchainNodeUrl() + '/sign-transaction',
            {
                wallet_data: wallet,
                transaction_data: res.data
            })
        if (!window.confirm(`On acceptance of this swap, you will be paying ${asset1_qty / getDecimal(asset1_code)} ${asset1_code} to get ${asset2_qty / getDecimal(asset2_code)} ${asset2_code}. Sure to confirm?`)) return
        transaction_data = res.data;
        axios.post(
          NEWRL_PAY_SERVICE + '/otcmarket/create-swap-requests',
          {
            newrl_transaction: transaction_data,
            rate: price
          }
        ).then(res => {
            if (res.status === 201) {
                alert('Swap request created successfully')
                window.location = "/otc-market/trade-list"
            } else {
                alert(res.text)
            }
        })
        .catch(e => {
            alert(JSON.stringify(e.response.data))
        })
          
        return 
        res = await axios.post(
            getBlockchainNodeUrl() + '/submit-transaction',
            res.data)
        console.log(res)
        if (res.status === 200 && res.data.response.valid) {
            alert('Transaction successfully sent to blockchain')
            setDestinationAddress('')
            setQuantity(0)
        }
    }

    const isValidWalletAddress = (address) => {
        return true; // disabling validation due to sc addresses
        // return /^0x([a-f0-9]){40,40}$/.test(address)
    }

    return (
        <Container maxWidth="xs">
        <Box style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            marginTop: '20px',
            // marginLeft: '10%', marginRight: '10%', 
            }}>
            <Typography variant="h6">New Trade</Typography>
            <FormControl style={{marginTop: '10px'}}>
              <FormLabel id="demo-row-radio-buttons-group-label">Trade Type</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(event) => {setTradeType(event.target.value)}}
                value={tradeType}
              >
                <FormControlLabel value="Buy" control={<Radio color="primary"/>} label="Buy" />
                <FormControlLabel value="Sell" control={<Radio color="primary"/>} label="Sell" />
              </RadioGroup>
            </FormControl>
            <FormControl fullWidth style={{marginTop: '10px'}}>
                <InputLabel id="token-select-label">{tradeType}</InputLabel>
                <Select
                    labelId="token-select-label"
                    id="token-select"
                    value={token}
                    label="Token to send"
                    disabled
                    onChange={(e) => {
                        setToken(e.target.value)
                        
                    }}
                >
                    {tokenList.map(b => <MenuItem value={b}>{b}</MenuItem>)}
                </Select>
            </FormControl>
            <FormControl fullWidth style={{marginTop: '10px'}}>
                <InputLabel id="token-select-label">Base Token</InputLabel>
                <Select
                    labelId="token-select-label"
                    id="token-select"
                    value={baseToken}
                    label="Token to send"
                    disabled
                    onChange={(e) => {
                        setBaseToken(e.target.value)
                        
                    }}
                >
                    {baseTokenList.map(b => <MenuItem value={b}>{b}</MenuItem>)}
                </Select>
            </FormControl>
            
            <TextField
                // variant="outlined"
                margin="normal"
                required
                // size="small"
                fullWidth
                name="txtrate"
                label={"Price ("+baseToken+")"}
                // helperText={maxQuantity && ('Max quantity ' + maxQuantity)}
                type="numeric"
                id="txtrate"
                value={price}
                onChange={event => setPrice (event.target.value)}
            />
            <TextField
                // variant="outlined"
                margin="normal"
                required
                // size="small"
                fullWidth
                name="txtamount"
                label="Quantity"
                helperText={maxQuantity !== undefined && ('Max quantity ' + maxQuantity)}
                type="numeric"
                id="txtamount"
                value={quantity}
                onChange={event => setQuantity (event.target.value)}
            />
            <Typography style={{margin: '10px'}}>{tokensRequired}</Typography>
            {nusdcToBuy > 0 && <Button
            variant="outlined"
            color="primary"
            onClick={() => {
                const amountString = nusdcToBuy.toFixed(6).toString();
                triggerNUSDCBuy(wallet.address, amountString)
                }}
            >Buy {nusdcToBuy.toFixed(6)} NUSDC</Button>}
            {/* <Typography style={{margin: '10px'}}>Fees: 1 NWRL (Paid by Seller)</Typography> */}
            <Button
                variant="contained"
                onClick={() => submitTrade()}
                // size="small"
                color="primary"
                fullWidth
                >
                Submit Trade
            </Button>
        </Box>
        </Container>
    )
}