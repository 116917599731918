import React, { useState, useEffect } from 'react';
import { Fab, Button, Typography, Grid, Container, Box, TextField, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Modal } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { getBlockchainNodeUrl } from '../globals/const';
import { getWalletFromDB, storeWalletToDB } from '../globals/db';
import { WalletTokensTable } from '../components/WalletTokensTable';
import { createOrder } from '../globals/fiat_payment';
import { BuyToken } from '../components/BuyToken';
import { TransferToken } from '../components/TransferToken';
import { ReceiveToken } from '../components/ReceiveToken';
import { ExpandMore } from '@material-ui/icons';
import { ActivateWallet } from '../components/ActivateWallet';
import { isWalletDownloaded, setWalletDownloaded } from '../globals/utils';
// const { randomBytes } = require('crypto')
// const secp256k1 = require('secp256k1')

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  mainContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    flexGrow: 1,
  },
  row: {
    item: true,
    container: true,
    spacing: 1,
    justify: 'center',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
  fabRow: {},
  fundSelectorRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonRow: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  walletModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    backgroundColor: 'white',
    // border: '2px solid #000',
    boxShadow: 24,
    padding: 10,
  }
}));

export default function WalletScreen() {
  const classes = useStyles();
  const [wallet, setWallet] = useState({});
  const [walletDetails, setWalletDetails] = useState();
  const [walletBalances, setWalletBalances] = useState([])
  const [isWalletActive, setIsWalletActive] = useState(false);
  const [walletAdditionRequestSent, setWalletAdditionRequestSent] = useState(false);
  const [walletVisible, setWalletVisible] = useState(false);
  const [showWalletDownloader, setShowWalletDownloader] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  async function addWallet() {
    const res = await axios.get(getBlockchainNodeUrl() + '/generate-wallet-address');
    // TODO - Do offline
    // do {
    //   privKey = randomBytes(32)
    //   console.log(privKey)
    // } while (!secp256k1.privateKeyVerify(privKey))
    const wallet = await res.data;
    console.log(wallet)
    storeWalletToDB(wallet);
    setWallet(wallet)
  }
  
  async function addLinkedWallet() {
    let res = await axios.get(getBlockchainNodeUrl() + '/generate-wallet-address');
    const newWallet = await res.data;
    
    const request = {
        "custodian_address": wallet['address'],
        "ownertype": "1",
        "jurisdiction": "910",
        "kyc_docs": [
          {
              "type": 1,
              "hash": ""
          }
        ],
        "specific_data": {
            "linked_wallet": true,
            "parentaddress": wallet['address']
        },
        "public_key": newWallet['public']
    }

    res = await axios.post(
      getBlockchainNodeUrl() + '/add-wallet',
      request
    );
    const unsignedTransaction = res.data;
    unsignedTransaction['transaction']['fee'] = 1000000;
    res = await axios.post(
        getBlockchainNodeUrl() + '/sign-transaction',
        {
            wallet_data: wallet,
            transaction_data: unsignedTransaction
        })
    if (!window.confirm(`Are you sure to add a linked wallet?`)) return
    res = await axios.post(
        getBlockchainNodeUrl() + '/submit-transaction',
        res.data)
    console.log(res)
    if (res.status === 200 && res.data.response.valid) {
        alert('Transaction successfully sent to blockchain')
        download('new_linked_wallet.json', JSON.stringify(newWallet))
    }
  }

  function download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
    setWalletDownloaded(wallet['address'])
    setShowWalletDownloader(false);
  }

  async function importWallet() {
    let walletJson = prompt('Enter wallet json: ')
    try {
      const wallet = JSON.parse(walletJson);
      if (!wallet) {
        throw "Empty wallet json"
      }
      storeWalletToDB(wallet);
      setWallet(wallet)
    } catch(e) {
      alert(e)
    }
    // let privateKeyString = prompt('Enter private key in hex:')
    // try {
    //   let privKey = Buffer.from(privateKeyString, 'hex')
    //   const pubKey = secp256k1.publicKeyCreate(privKey)
    //   let private_hex = Buffer.from(privKey).toString('hex');
    //   let public_hex = Buffer.from(pubKey).toString('hex');
    //   console.log(privKey, private_hex, public_hex)
    // } catch(e) {
    //   alert('Invalid private key')
    // }
  }

  async function activateWallet() {
    const url = 'https://una8h1o5hk.execute-api.ap-south-1.amazonaws.com/default/NewrlWalletAdd?public_key=' + encodeURIComponent(wallet.public);
    window.open(url)
    setWalletAdditionRequestSent(true)
  }

  const getWalletDetails = async () => {
    if (!wallet.address) return
    axios.get(getBlockchainNodeUrl() + '/get-wallet?wallet_address=' + wallet.address)
    .then(res => {
      if (res.status === 200) {
        const _walletDetails = res.data;
        setWalletDetails(_walletDetails)
        setIsWalletActive(true)
      } else {
        setIsWalletActive(false);
        console.log('Wallet is not active on the chain')
      }
    })
    .catch(err => {
      if (err.response) {
        // Connection to server successful, but server returned an error
        setDataLoaded(true);
      } else {
        // Connection to server failed
        setDataLoaded(false);
        alert('Failed to get wallet details from chain');
      }
    });
  }

  const exportWallet = () => {
    if(window.confirm('This will expose your private key on screen. Are you sure?')) {
      setWalletVisible(true)
    }
  }

  useEffect(() => {
    getWalletFromDB((wallet) => { if (wallet) setWallet(wallet) });

  }, []);

  useEffect(() => {
    getWalletDetails()

    async function decideWalletDownload() {
      if (!wallet['address']) return;
      const walletDownloaded = await isWalletDownloaded(wallet['address']);
      console.log('w', !walletDownloaded)
      setShowWalletDownloader(!walletDownloaded);
    }
    decideWalletDownload();

  }, [wallet]);

  return (
    <Container maxWidth="xs">
      <div className={classes.mainContent}>
        <Grid
          container
          // spacing={1}
          justify="center"
          direction="column"
          style={{ marginTop: '10px' }}
        >
          {/* FundSelector */}
          <Grid container item>
            <Grid item xs>
              {wallet.address ?
                <>
                  <Typography variant="subtitle1">Wallet Address:</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs>
                  <TextField 
                    fullWidth value={wallet.address} size='small'
                    onFocus={event => {
                      event.target.select();
                    }}
                    // onClick={() => {navigator.clipboard.writeText(wallet.address); alert('Address copied')}}
                    />
                    </Grid>
                    <Grid item xs={4}>
                    <Button
                        variant="contained"
                        onClick={() => download('wallet.json', JSON.stringify(wallet))}
                        size="small"
                        color="primary"
                        fullWidth
                      >
                      Download
                    </Button>
                    </Grid>
                    </Grid>
                  {isWalletActive ?
                    <>
                      {/* <Typography>Wallet is active on the chain</Typography> */}
                      <WalletTokensTable 
                        walletAddress={wallet.address}
                        setWalletBalancesParent={setWalletBalances}/>
                      {/* <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Buy</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <BuyToken walletAddress={wallet.address}/>
                        </AccordionDetails>
                      </Accordion> */}
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Send Token</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TransferToken wallet={wallet} balances={walletBalances}/>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Receive Token</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ReceiveToken walletAddress={wallet.address}/>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Linked Wallet</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Button
                            variant="contained"
                            onClick={addLinkedWallet}
                            size="small"
                            color="primary"
                            fullWidth
                          >
                            Add Linked Wallet
                          </Button>
                        </AccordionDetails>
                      </Accordion>
                      {/* <TransferToken wallet={wallet} balances={walletBalances}/>
                      <ReceiveToken walletAddress={wallet.address}/> */}
                    </>
                    :
                    <>
                    {dataLoaded ? <Box className={classes.buttonRow}>
                      {!walletAdditionRequestSent ?
                          <ActivateWallet wallet={wallet} setWalletAdditionRequestSent={setWalletAdditionRequestSent}/>
                        :
                        <Button
                          variant="contained"
                          onClick={getWalletDetails}
                          size="small"
                          color="primary"
                        >
                          Refresh
                        </Button>
                      }
                    </Box>
                    : 
                    <Box m={2}>
                      <Typography>Getting wallet details from chain...</Typography>
                    </Box>
                    }
                    </>
                  }
                </>
                :
                <Box className={classes.buttonRow} style={{marginTop: '100px'}}>
                  
                      {/* <button onClick={this.addWallet}>Add Wallet</button> */}
                      <Button
                        //   className={classes.root}
                        variant="contained"
                        onClick={addWallet}
                        // size="small"
                        color="primary"
                        style={{ marginRight: 10 }}
                      >
                        Add Wallet
                      </Button>

                      <Button variant="contained"
                        color="primary"
                        style={{ marginRight: 10 }}
                        onClick={importWallet}>
                        Import Wallet
                      </Button>
                    
                      {/* <button onClick={this.importWallet}>Import Wallet</button> */}
                      {/* <Button
                        variant="contained"
                        onClick={importWallet}
                        // size="small"
                        color="primary"
                      >
                        Import Wallet
                      </Button> */}
                    
                </Box>
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Modal
        open={walletVisible}
        onClose={() => setWalletVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.walletModal}>
          
            <TextField
                  placeholder="Enter you transaction JSON"
                  multiline
                  rows={6}
                  fullWidth
                  maxRows={8}
                  style={{ marginBottom: '10px' }}
                  value={JSON.stringify(wallet)}
                />
        </Box>
      </Modal>
      <Modal
        open={showWalletDownloader}
        // onClose={() => setWalletVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.walletModal}>
          <Typography>It's important to download and store your wallet safely and securely.
            Losing the wallet can result in loss of funds and cannot be recovered by anyone.
          </Typography>
          <Button
                      variant="contained"
                      onClick={() => download('wallet.json', JSON.stringify(wallet))}
                      size="small"
                      color="primary"
                      fullWidth
                    >
                    Download
                  </Button>
        </Box>
      </Modal>
    </Container>
  );
}
