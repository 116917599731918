import React from 'react';
import HawkEyeAppBar from '../components/AppBar';
import Login from './Login';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import {HOME_PAGE} from '../globals/const';
import Register from './Register';
import {isLoggedIn} from '../globals/utils';
import { initDB } from '../globals/db';
import WalletScreen from './WalletScreen';
import { TransferToken } from '../components/TransferToken';
import TrustnetScreen from './TrustnetScreen';
import SignTransaction from './SignTransaction';
import Faucet from './Faucet';
import ComingSoon from './ComingSoon';
import RunNode from './RunNode';
import NodeTester from './NodeTester';
import StableCoins from './StableCoins';
import OTCMarket from './OTCMarket';
import OTCMarketTradeList from './OTCMarketTradeList';

initDB(() => {});

export default function AppScreen () {
  const DefaultContainer = () => (
    <div>
      <HawkEyeAppBar />
      {isLoggedIn () &&
        <React.Fragment>
          <Route exact path={'/'} component={WalletScreen} />
          <Route exact path={'/wallet'} component={WalletScreen} />
          <Route path={'/transfer'} component={TransferToken} />
          <Route path={'/trustnet'} component={TrustnetScreen} />
          <Route path={'/sign-transaction'} component={SignTransaction} />
          <Route path={'/faucet'} component={Faucet} />
          <Route path={'/daos'} component={ComingSoon} />
          <Route path={'/dex'} component={ComingSoon} />
          <Route path={'/otc-market/trade-list'} component={OTCMarketTradeList} />
          <Route path={'/otc-market/new-trade'} component={OTCMarket} />
          <Route path={'/run-node'} component={RunNode} />
          <Route path={'/stable-coins'} component={StableCoins} />

          {/* <Route exact path={HOME_PAGE} component={FundSelectionScreen} /> */}
          {/* <Route
            path={HOME_PAGE + '/my-funds'}
            component={FundSelectionScreen}
          /> */}
        </React.Fragment>}
    </div>
  );

  return (
    <Router>
      <Switch>
      
        <Route path={HOME_PAGE + '/login'} component={Login} />
        <Route path={HOME_PAGE + '/signup'} component={Register} />
        <Route path={HOME_PAGE + '/faucet'} component={Faucet} />
        <Route path={HOME_PAGE + '/test-node'} component={NodeTester} />

        <Route component={DefaultContainer} />
      </Switch>
    </Router>
  );
}
