import React, { useEffect, useState } from "react";

import axios from 'axios'
import { getBlockchainNodeUrl, NEWRL_PAY_SERVICE, NUSDC_TOKEN_CODE } from "../globals/const";
import { Box, Button, Typography, FormControl, InputLabel, MenuItem, Select, Container } from "@material-ui/core";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel } from "@material-ui/core";

import { FormControlLabel, Radio, RadioGroup, FormLabel } from "@material-ui/core";
import { Fab } from "@material-ui/core";
import { getEthWallet, triggerNUSDCBuy } from "../globals/fiat_payment";
import { getWalletFromDB } from "../globals/db";
import { getDecimal } from "../globals/utils";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: 20,
        marginBottom: 20,
        paddingBottom: 20,
    },
    tradeCard: {
        margin: 10,
        padding: 10,
    },
    tradeListBox: {
        margin: 10,
    },
    centeredRow: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 10,
    },
    newTradeButton: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
    tableCell: {
        paddingRight: 4,
        paddingLeft: 5
    },
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    notes: {
        marginTop: 10,
        marginBottom: 10,
    },
}));

export default function OTCMarketTradeList() {
    const classes = useStyles();

    const [token, setToken] = useState('NWRL');
    const [baseToken, setBaseToken] = useState(NUSDC_TOKEN_CODE);
    const [wallet, setWallet] = useState({});
    const [walletBalances, setWalletBalances] = useState([]);
    const [tokenList, setTokenList] = useState(['NWRL']);
    const [baseTokenList, setBaseTokenList] = useState([NUSDC_TOKEN_CODE]);
    const [tradeType, setTradeType] = useState('Buy');
    const [tradeList, setTradeList] = useState([]);
    
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('price');

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortData = (data) => {
        return data.sort((a, b) => {
            if (orderBy === 'price') {
                return (order === 'asc' ? a.price - b.price : b.price - a.price);
            } else if (orderBy === 'quantity') {
                return (order === 'asc' ? a.nwrlQty - b.nwrlQty : b.nwrlQty - a.nwrlQty);
            } else if (orderBy === 'total') {
                return (order === 'asc' ? a.usdcQty - b.usdcQty : b.usdcQty - a.usdcQty);
            }
            return 0;
        });
    };

    useEffect(() => {
        getWalletFromDB((wallet) => { if (wallet) setWallet(wallet) });

    }, []);

    useEffect(() => {
        // if (!balances) {
            axios.get(getBlockchainNodeUrl() + '/get-balances?balance_type=ALL_TOKENS_IN_WALLET&wallet_address=' + wallet.address)
            .then(res => res.data.balance)
            .then(data => {
                console.log(data)
                // setToken(data[0]?.token_code)
                setWalletBalances(data)
            })
        // }
    }, [wallet])

    useEffect(() => {
        loadTrades(tradeType, baseToken, token)

    }, [tradeType, baseToken, token]);

    const loadTrades = async (_tradeType, _baseToken, _token) => {
        const from_token = _tradeType === 'Buy' ? _token : _baseToken;
        const to_token = _tradeType === 'Buy' ? _baseToken : _token;
        let res = await axios.get(
            NEWRL_PAY_SERVICE + `/otcmarket/list-swap-requests?from_token=${from_token}&to_token=${to_token}`
        );
        
        const updatedTrades = res.data.map(trade => {
            let nwrlQty = trade.from_token === "NWRL" ? trade.from_qty : trade.to_qty;
            nwrlQty = nwrlQty / getDecimal("NWRL");
            let usdcQty = trade.from_token === "NWRL" ? trade.to_qty : trade.from_qty;
            usdcQty = usdcQty / getDecimal("NWRL");
            const price = (usdcQty / nwrlQty).toFixed(4);
            return { ...trade, price: parseFloat(price), nwrlQty, usdcQty };
        });
    
        setTradeList(updatedTrades);
    }

    const acceptTrade = async (trade) => {
        const asset1_code = trade.newrl_transaction.transaction.specific_data.asset1_code;
        const asset1_number = trade.newrl_transaction.transaction.specific_data.asset1_number / getDecimal(asset1_code);
        const asset2_code = trade.newrl_transaction.transaction.specific_data.asset2_code;
        const asset2_number = trade.newrl_transaction.transaction.specific_data.asset2_number / getDecimal(asset2_code);

        console.log(trade)
        const asset2_balance = walletBalances.find((b) => b.token_code === asset2_code)?.balance / getDecimal(asset2_code);
        console.log(asset2_balance, asset2_number)
        if (isNaN(asset2_balance) || asset2_balance < asset2_number) {
            let diff = isNaN(asset2_balance) ? asset2_number : asset2_number - asset2_balance;
            diff = diff.toFixed(6);
            if (!window.confirm(`Your ${asset2_code} balance is low for this trade. Would you like to buy ${diff} ${asset2_code}?`)) return    
            if (asset2_code === NUSDC_TOKEN_CODE) {
                triggerNUSDCBuy(wallet['address'], diff.toString())
            } else {
                window.location.href=(`/faucet?wallet_to_fund=${wallet['address']}&qty=${diff}`)
            }
            return
        }
        const newrl_txn = JSON.parse(trade.newrl_transaction_string)
        const res = await axios.post(
            getBlockchainNodeUrl() + '/sign-transaction',
            {
                wallet_data: wallet,
                transaction_data: newrl_txn
            })
        if (!window.confirm(`You will be paying ${asset2_number} ${asset2_code} to get ${asset1_number} ${asset1_code}. Sure to confirm?`)) return
        const signedTxn = res.data;
        console.log(signedTxn)
        axios.post(
            NEWRL_PAY_SERVICE + '/otcmarket/accept-swap-request',
            {
                signed_txn: signedTxn
            }
        ).then(res => {
            if (res.status === 200) {
                alert('Txn submitted successfully')
                window.location = "/otc-market/trade-list"
                // TODO - Open Newrl scan on new page

            } else {
                alert('Failed to submit transaction')
            }
        })
            .catch(e => {
                alert(JSON.stringify(e.response.data))
                // alert(e.response.data.detail)
            })

    }

    const deleteTrade = async (trade) => {
        const trans_code = trade.trans_code
        // const signedTX = await newrl.signTransaction(wallet, { transaction: { trans_code: trans_code }, signatures: [] });
        let res = await axios.post(
            getBlockchainNodeUrl() + '/add-transfer',
            {
                transfer_type: 5,
                asset1_code: 'NWRL',
                asset2_code: '',
                wallet1_address: wallet.address,
                wallet2_address: '0x0',
                asset1_qty: 0,
                asset2_qty: 0,
                description: trans_code
            }
        );
        const transaction_data = res.data;
        res = await axios.post(
        getBlockchainNodeUrl() + '/sign-transaction',
        {
            wallet_data: wallet,
            transaction_data: transaction_data
        })
        if (!window.confirm(`You will be deleting transaction ${trans_code}. Sure to confirm?`)) return
        // console.log(signedTX)
        axios.post(
            NEWRL_PAY_SERVICE + '/otcmarket/delete-swap-request',
            {
                auth_txn: res.data
            }
        ).then(res => {
            if (res.status === 200) {
                alert('Trade deleted')
                window.location = "/otc-market/trade-list"
                // TODO - Open Newrl scan on new page

            } else {
                alert('Failed to submit transaction')
            }
        })
    }

    function openNUSDCBuy() {
        const amount = window.prompt('Enter NUSDC amount to buy: ')
        triggerNUSDCBuy(wallet['address'], amount)
    }
    
    async function openNUSDCSell() {
        let amount = window.prompt('Enter NUSDC amount to sell: ')
        amount = parseFloat(amount);
        const amountMultiplied = amount * getDecimal("NUSDC");
        const ethWallet = await getEthWallet();
        console.log('ethwallet', amount, ethWallet);

        const NUSDC_CUSTODIAN_WALLET = "0x794cdb15ca0825a03b3aea720496efd702b3ccc9";

        let res = await axios.post(
            getBlockchainNodeUrl() + '/add-transfer',
            {
                transfer_type: 5,
                asset1_code: 'NUSDC',
                asset2_code: '',
                wallet1_address: wallet.address,
                wallet2_address: NUSDC_CUSTODIAN_WALLET,
                asset1_qty: amountMultiplied,
                asset2_qty: 0,
                additional_data: {
                    ethWallet: ethWallet
                }
            }
        );
        const transaction_data = res.data;
        transaction_data['transaction']['fee'] = 1000000;
        res = await axios.post(
        getBlockchainNodeUrl() + '/sign-transaction',
        {
            wallet_data: wallet,
            transaction_data: transaction_data
        })
        console.log('signdTx', res.data)
        if (!window.confirm(`You will be converting ${amount} NUSDC to USDC and wallet ${ethWallet} will be credited. Sure to confirm?`)) return
        
        axios.post(
            NEWRL_PAY_SERVICE + '/nusdcapp/sell', res.data
        ).then(res => {
            if (res.status === 200) {
                alert('Offramp request submitted')
                window.location = "/"
            } else {
                alert('Failed to submit transaction')
            }
        })
    }

    function BoxDisplay(props) {
        console.log(props.t)
        if (wallet.address == props.t.newrl_transaction.transaction.specific_data.wallet1) {
            return (<Box className={classes.centeredRow}>
                <Button style={{ marginRight: '10px' }}
                    // Trade Button
                    variant="contained"
                    onClick={() => acceptTrade(props.t)}
                    // size="small"
                    color="primary"
                // fullWidth
                >
                    Trade
                </Button>
                <Button style={{ marginLeft: '10px' }}
                    // Delete Button
                    variant="contained"
                    onClick={() => deleteTrade(props.t)}
                    // size="small"
                    color="secondary"
                // fullWidth
                >
                    Delete
                </Button>
            </Box>);
        } else {
            return (<Box className={classes.centeredRow}>
                <Button
                    // Trade Button
                    variant="contained"
                    onClick={() => acceptTrade(props.t)}
                    // size="small"
                    color="primary"
                // fullWidth
                >
                    Trade
                </Button>
            </Box>);
        }

    }

    function renderTradeRow(trade) {
        return (
            <TableRow key={trade.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell} align="right">{trade.price}</TableCell>
                <TableCell className={classes.tableCell} align="right">{trade.nwrlQty}</TableCell>
                <TableCell className={classes.tableCell} align="right">{trade.usdcQty}</TableCell>
                <TableCell className={classes.tableCell}>{trade.date_time}</TableCell>
                <TableCell className={classes.tableCell}>{trade.newrl_transaction.transaction.specific_data.wallet1}</TableCell>
                <TableCell className={classes.tableCell}>
                    <BoxDisplay t={trade} />
                </TableCell>
            </TableRow>
        );
    }

    return (
        <Container maxWidth="md" className={classes.container}>
            <Box style={{
                // width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                marginTop: '20px',
                // marginLeft: '10%', marginRight: '10%', 
            }}>
                <Typography variant="h6">Available Trades</Typography>
                <FormControl style={{ marginTop: '10px' }}>
                    <FormLabel id="demo-row-radio-buttons-group-label">Trade Type</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(event) => { setTradeType(event.target.value) }}
                        value={tradeType}
                        style={{ justifyContent: 'center' }}
                    >
                        <FormControlLabel value="Buy" control={<Radio color="primary" />} label="Buy NWRL" />
                        <FormControlLabel value="Sell" control={<Radio color="primary" />} label="Sell NWRL" />
                    </RadioGroup>
                </FormControl>
                <FormControl fullWidth style={{ marginTop: '10px' }}>
                    <InputLabel id="token-select-label">{tradeType}</InputLabel>
                    <Select
                        labelId="token-select-label"
                        id="token-select"
                        value={token}
                        label="Token to send"
                        disabled
                        onChange={(e) => {
                            setToken(e.target.value)

                        }}
                    >
                        {tokenList.map(b => <MenuItem value={b}>{b}</MenuItem>)}
                    </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginTop: '10px' }}>
                    <InputLabel id="token-select-label">Base Token</InputLabel>
                    <Select
                        labelId="token-select-label"
                        id="token-select"
                        value={baseToken}
                        label="Token to send"
                        disabled
                        onChange={(e) => {
                            setBaseToken(e.target.value)

                        }}
                    >
                        {baseTokenList.map(b => <MenuItem value={b}>{b}</MenuItem>)}
                    </Select>
                </FormControl>
                <Box >
                    <TableContainer component={Paper} style={{ marginTop: '20px', padding: '10px' }}>
                        <Table className={classes.table} aria-label="trade table">
                            <TableHead>
                                <TableRow>
                                <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'price'}
                                            direction={orderBy === 'price' ? order : 'asc'}
                                            onClick={() => handleSortRequest('price')}
                                        >
                                            Price
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'quantity'}
                                            direction={orderBy === 'quantity' ? order : 'asc'}
                                            onClick={() => handleSortRequest('quantity')}
                                        >
                                            Quantity (NWRL)
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'total'}
                                            direction={orderBy === 'total' ? order : 'asc'}
                                            onClick={() => handleSortRequest('total')}
                                        >
                                            Total (NUSDC)
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Time</TableCell>
                                    <TableCell>From Wallet</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tradeList.length > 0 ? sortData(tradeList).map(trade => renderTradeRow(trade)) :
                                    <TableRow>
                                        <TableCell colSpan={5} style={{ textAlign: "center" }}>
                                            No Pending trades available. Post a new one.
                                        </TableCell>
                                    </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box className={classes.notes}>
                    <Typography variant="subtitle2">Price is (Base Token / Token)</Typography>
                    <Typography variant="subtitle2">Quantity is of NWRL</Typography>
                </Box>
                <Box>
                    <a href="#" onClick={openNUSDCBuy}>Buy Custom NUSDC Quantity</a>
                </Box>
                <Box>
                    <a href="#" onClick={openNUSDCSell}>Convert NUSDC to USDC</a>
                </Box>

                <Fab className={classes.newTradeButton} color="primary" aria-label="add" variant="extended"
                    onClick={() => window.location = "/otc-market/new-trade"}>
                    {/* <AddIcon /> */}
                    New Trade
                </Fab>
            </Box>
        </Container >
    )
}