import axios from 'axios';
import { getBlockchainNodeUrl, SERVER_BASE_ADDRESS } from './const';
import React from 'react';
import { isAuthenticated } from './authentication';

export const getFromBackend = (url, useAuth=true) => {
  // url = url.replace('&', '%26');
  // console.log(url);
  const token = window.localStorage.getItem('token');

  var headers;
  if(useAuth){
    headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + token
    }
  }else{
    headers = {
      'Content-Type': 'application/json',
    }
  }

  return axios.get(SERVER_BASE_ADDRESS + url, {headers: headers})
  .then(result => result.data);
  // .catch(e => {if(e.response.status === 401){
  //   alert('Session expired. Please login again.');
  //   window.location.href='login';
  // }})
}

export const postToBackend = (url, data) => {
  const token = window.localStorage.getItem('token');

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Token ' + token
  }

  return axios.post(SERVER_BASE_ADDRESS + url, data, {headers: headers})
  .then(result => result.data);
  // .catch(e => {if(e.response.status === 401){
  //   alert('Session expired. Please login again.');
  //   window.location.href='login';
  // }})
  
}

export function logout() {
  // postToBackend('/auth/logout')
  // .then(result => result.data)
  // .finally(result => {
  //     window.localStorage.removeItem('userid');
  //     window.localStorage.removeItem('username');
  //     window.localStorage.removeItem('token');
  //     window.location.href = HOME_PAGE + '/login';
  // });
  console.log('logging out trigerred')
  document.cookie = 'pass=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  //window.location = "/";
  window.location = "/";
}

export function ValidateEmail(mail) 
{
 if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return (true)
  }
    alert("You have entered an invalid email address!")
    return (false)
}

export function isLoggedIn(){
  return isAuthenticated();
  // return window.localStorage.getItem ('token') !== undefined
  //       && window.localStorage.getItem ('token') !== null;
}

export function getColorForRiskWording(riskValue) {
  switch(riskValue){
      case 'Very Low':
          return '#29812c';
      case 'Low':
          return '#8bc34a';
      case 'Medium':
          return '#ffc107';
      case 'High':
          return '#e36944';
      case 'Very High':
          return '#ee2e31';
      default:
          return 'black';
  }
}

export function getColorForRecommendation(recommendation) {
  var backgroundColor = 'white';
  var fontColor = 'black';
      
  switch(recommendation){
  case 'STRONG_BUY':
      backgroundColor = '#29812c';
      fontColor = 'white';
      break;
  case 'BUY':
      backgroundColor = '#8bc34a';
      fontColor = 'white';
      break;
  case 'HOLD': case 'WEAK_HOLD': case 'STRONG_HOLD':
      backgroundColor = '#ffbf00';
      fontColor = 'white';
      break;
  case 'SELL': case 'WEAK_SELL':
      backgroundColor = '#e36944';
      fontColor = 'white';
      break;
  case 'STRONG_SELL':
      backgroundColor = '#ee2e31';
      fontColor = 'white';
      break;
  default:
    break;
  }

  return {backgroundColor: backgroundColor, fontColor: fontColor}
}

export function getRiskReturnComparisonBigText(verdict) {
  switch(verdict){
      case 'Risk << Returns':
          return <div><span style={{fontSize: '12px'}}>Risk</span> &lt; <span style={{fontSize: '16px'}}>Returns</span></div>;
      case 'Risk < Returns':
          return <div><span style={{fontSize: '12px'}}>Risk</span> &lt; <span style={{fontSize: '14px'}}>Returns</span></div>;
      case 'Risk ~ Returns':
          return <div><span style={{fontSize: '12px'}}>Risk</span> ~ <span style={{fontSize: '12px'}}>Returns</span></div>;
      case 'Risk > Returns':
          return <div><span style={{fontSize: '14px'}}>Risk</span> &gt; <span style={{fontSize: '12px'}}>Returns</span></div>;
      case 'Risk >> Returns':
          return <div><span style={{fontSize: '16px'}}>Risk</span> &gt; <span style={{fontSize: '12px'}}>Returns</span></div>;
      default:
          return '';
  }
}

export const scaleImageMap = {
  'Risk << Returns': '/hawkeye/images/report/tradeoff-inverted.jpg',
  'Risk < Returns': '/hawkeye/images/report/tradeoff-inverted.jpg',
  'Risk ~ Returns': '/hawkeye/images/report/equalriskreturn.png',
  'Risk > Returns': '/hawkeye/images/report/tradeoff.jpg',
  'Risk >> Returns': '/hawkeye/images/report/tradeoff.jpg',
}

export const isValidWalletAddress = (address) => {
  return /^0x([a-f0-9]){40,40}$/.test(address)
}

export const signTransaction = async (transaction, wallet) => {
  let res = await axios.post(
  getBlockchainNodeUrl() + '/sign-transaction',
  {
    wallet_data: wallet,
    transaction_data: transaction
  })
  return res.data;
}

export const isWalletDownloaded = async (walletAddress) => {
  const walletDownloaded = localStorage.getItem('DOWNLOADED:' + walletAddress);
  console.log((walletDownloaded === 'yes'))
  return (walletDownloaded === 'yes');
}

export const setWalletDownloaded = async (walletAddress) => {
  localStorage.setItem('DOWNLOADED:' + walletAddress, 'yes');
}

export const getDecimal = (token_code) => {
  if (['NWRL', 'NUSDC', 'NUSDCTEST'].includes(token_code)) {
      return 1000000;
  } else {
      return 1;
  }
}