import React, { useState, useEffect } from 'react';
import { Fab, Button, Typography, Grid, Container, Box, TextField, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { getBlockchainNodeUrl, NWRL_DECIMAL_MULTIPLIER } from '../globals/const';
import { getWalletFromDB, storeWalletToDB } from '../globals/db';
import { WalletTokensTable } from '../components/WalletTokensTable';
import { createOrder } from '../globals/fiat_payment';
import { BuyToken } from '../components/BuyToken';
import { TransferToken } from '../components/TransferToken';
import { ReceiveToken } from '../components/ReceiveToken';
import { ExpandMore } from '@material-ui/icons';
import { ActivateWallet } from '../components/ActivateWallet';
import { isValidWalletAddress } from '../globals/utils';
// const { randomBytes } = require('crypto')
// const secp256k1 = require('secp256k1')

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  mainContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    flexGrow: 1,
  },
  row: {
    item: true,
    container: true,
    spacing: 1,
    justify: 'center',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
  fabRow: {},
  fundSelectorRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonRow: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-around',
  }
}));

export default function RunNode() {
  const classes = useStyles();
  const [wallet, setWallet] = useState({});
  const [destinationAddress, setDestinationAddress] = useState();
  const [stakeAmount, setStakeAmount] = useState(0);
  const [nodeStatus, setNodeStatus] = useState('');
  const [nodeFound, setNodeFound] = useState(null);
  const [nodeIP, setNodeIP] = useState();
  const [stakeStatus, setStakeStatus] = useState('');
  const [staked, setStaked] = useState(false);
  const [stakesAvailable, setStakesAvailable] = useState(null);
  const [miningStatus, setMiningStatus] = useState('');
  const [ogAvailable, setOgAvailable] = useState(false);
  const [nwrlAvailable, setNwrlAvailable] = useState(false);
  const [issues, setIssues] = useState([]);
  const [issuesLoaded, setIssuesLoaded] = useState(false);

  useEffect(() => {
    getWalletFromDB((wallet) => { if (wallet) setWallet(wallet) });

  }, []);
  
  useEffect(() => {
    if (wallet.address !== undefined) {
      setDestinationAddress(wallet.address);

    axios.get(getBlockchainNodeUrl() + '/sc-state?table_name=stake_ledger&contract_address=ct1111111111111111111111111111111111111115&unique_column=wallet_address&unique_value=' + wallet.address)
    .then(res => res.data)
    .then(res => {
      if (res.data) {
        const _stakesAvailable = res.data[3] / NWRL_DECIMAL_MULTIPLIER;
        setStakeStatus('Stake available for wallet: ' + _stakesAvailable)
        setStakesAvailable(_stakesAvailable)
        setStaked(true)
      } else {
        setStakesAvailable(0)
        setStakeStatus('No stake exists for wallet')
        setStaked(false)
      }
    })
    
    axios.get(getBlockchainNodeUrl() + '/get-balances?balance_type=TOKEN_IN_WALLET&token_code=NEWRL_OG&wallet_address=' + wallet.address)
    .then(res => {
      setOgAvailable(res.data.balance > 0)
    })

    checkValidatorNodeExists();

    loadIssues();
    }
  }, [wallet]);

  useEffect(() => {
    axios.get(getBlockchainNodeUrl() + '/get-miners')
    .then(res => res.data)
    .then(res => {
      const found = res.eligible_miners.find((m) => m.wallet_address === wallet.address);
      console.log(res.eligible_miners, found)

      if (found) {
        setMiningStatus(`All well! Your node ${found.network_address} is a validator.`)
      } else {
        if (stakesAvailable >= 500000) {
          if (nodeFound) {
            setMiningStatus(`Stakes are adequate for your wallet and a node is running at ${nodeIP}. 
            Wait for a miner update transaction(can take upto 1 hour). To stake more, use the below form.`)

          } else {
            setMiningStatus(`Stakes are adequate for your wallet but a node could not be found running for the wallet.
            To start validating, run a node and import your wallet.`)
          }
        }
      }
    })
  }, [stakesAvailable, nodeFound])

  const stakeForWallet = async () => {
    if (!isValidWalletAddress(destinationAddress)) {
      alert('Invalid wallet address')
      return;
    }
    const stake_payload = {
      "sc_address": "ct1111111111111111111111111111111111111115",
      "function_called": "stake_tokens",
      "signers": [
        wallet['address']
      ],
      "params": {
          "value": [
              {
                  "token_code": "NWRL",
                  "amount": parseInt(stakeAmount * NWRL_DECIMAL_MULTIPLIER)
              }],
              "token_amount": parseInt(stakeAmount * NWRL_DECIMAL_MULTIPLIER),
              "wallet_address": destinationAddress
          
      }
    }
    let res = await axios.post(
      getBlockchainNodeUrl() + '/call-sc',
        stake_payload
    );
    const unsignedTransaction = res.data;
    unsignedTransaction['transaction']['fee'] = 1000000;
    res = await axios.post(
        getBlockchainNodeUrl() + '/sign-transaction',
        {
            wallet_data: wallet,
            transaction_data: unsignedTransaction
        })
    if (!window.confirm(`Your wallet will be debited with ${stakeAmount} NWRL token(s). Are you sure to proceed?`)) return
    res = await axios.post(
        getBlockchainNodeUrl() + '/submit-transaction',
        res.data)
    console.log(res)
    if (res.status === 200 && res.data.response.valid) {
        alert('Transaction successfully sent to blockchain')
        setStakeAmount(0)
    }
  }
  
  const unStakeForWallet = async () => {
    if (!isValidWalletAddress(destinationAddress)) {
      alert('Invalid wallet address')
      return;
    }
    if (stakeAmount === 0) {
      alert('Please enter a valid stake amount')
      return;
    }
    let walletRes = await axios.get(
      getBlockchainNodeUrl() + '/get-wallet?wallet_address=' + destinationAddress
    );
    const personId = walletRes.data.person_id;

    const unstake_payload = {
      "sc_address": "ct1111111111111111111111111111111111111115",
      "function_called": "unstake_tokens",
      "signers": [
        wallet.address
      ],
      "params":{
          "person_id": personId,
          "wallet_address": destinationAddress,
          "token_amount": parseInt(stakeAmount * NWRL_DECIMAL_MULTIPLIER),
      }
    }
    let res = await axios.post(
      getBlockchainNodeUrl() + '/call-sc',
        unstake_payload
    );
    const unsignedTransaction = res.data;
    unsignedTransaction['transaction']['fee'] = 1000000;
    res = await axios.post(
        getBlockchainNodeUrl() + '/sign-transaction',
        {
            wallet_data: wallet,
            transaction_data: unsignedTransaction
        })
    if (!window.confirm(`Continuing will unstake ${stakeAmount} of NWRL token(s) for personId ${personId}. Are you sure to proceed?`)) return
    res = await axios.post(
        getBlockchainNodeUrl() + '/submit-transaction',
        res.data)
    console.log(res)
    if (res.status === 200 && res.data.response.valid) {
        alert('Transaction successfully sent to blockchain')
        setStakeAmount(0)
    }
  }
  
  const stakeUsingOg = async () => {
    const stake_payload = {
      "sc_address": "ct689d36bd38e70f86d470a0eaee358b531334dd22",
      "function_called": "stake",
      "signers": [
        wallet['address']
      ],
      "params": {
        "wallet_address": wallet['address'],
        "value": [
            {
                "token_code": "NEWRL_OG",
                "amount": 1
            }
        ]
    }
    }
    let res = await axios.post(
      getBlockchainNodeUrl() + '/call-sc',
        stake_payload
    );
    const transactionData = res.data;
    transactionData['transaction']['fee'] = 1000000;
    res = await axios.post(
        getBlockchainNodeUrl() + '/sign-transaction',
        {
            wallet_data: wallet,
            transaction_data: transactionData
        })
    if (!window.confirm(`Your wallet will be debited with ${stakeAmount} NWRL token(s). Are you sure to proceed?`)) return
    res = await axios.post(
        getBlockchainNodeUrl() + '/submit-transaction',
        // 'http://localhost:8456' + '/submit-transaction',
        res.data)
    console.log(res)
    if (res.status === 200 && res.data.response.valid) {
        alert('Transaction successfully sent to blockchain')
        setDestinationAddress('')
        setStakeAmount(0)
    }
  }

  const checkValidatorNodeExists = () => {
    if (!wallet) return;
    axios.get('https://mainnet-scanner-api.newrl.net/get-nodes')
    .then((res) => {
      const node = res.data.find((n) => n.walletAddress === wallet.address)
      console.log(wallet.address)
      if (node) {
        setNodeStatus(`Node found for your wallet with IP ${node.ipAddress}`)
        setNodeIP(node.ipAddress)
        setNodeFound(true)
      } else {
        setNodeStatus(`Node found not found for your wallet`)
        setNodeFound(false)
      }
    })
  }

  const loadIssues = () => {
    axios.get('https://diagnose.newrl.net/diagnose?wallet_or_ip=' + wallet.address)
    .then((res) => {
      setIssues(res.data)
      setIssuesLoaded(true)
    })
  }

  let loaded = stakesAvailable != null && nodeFound !== null;

  return (
    <Container maxWidth="xs">
      <div className={classes.mainContent}>
        <Grid
          container
          // spacing={1}
          justify="center"
          direction="column"
          style={{ marginTop: '10px' }}
        >
         <Typography variant='h5'>Validator Node Management</Typography>
         {loaded && !nodeFound ? <>
          <Typography style={{ marginTop: '10px' }}>Validator node not found for this wallet</Typography>
         <Button 
          variant="contained"
          size="small"
          color="primary"
          onClick={() => window.open('https://docs.newrl.net/Validating/running-validator-node')}>Setup Validating Node</Button>
          </>
          :
          <>
            <Typography style={{ marginTop: '10px' }}>{nodeStatus}</Typography>
            {/* <Button 
              variant="contained"
              size="small"
              color="primary"
              onClick={stakeUsingOg}>Broadcast miner update now
            </Button> */}
          </>
          }
          <Typography style={{ marginTop: '10px' }}>{stakeStatus}</Typography>
          <Typography style={{ marginTop: '10px' }}>{miningStatus}</Typography>
          {
            !issuesLoaded && <Typography>Diagnosing your node...</Typography>
          }
          {issuesLoaded && issues.length > 0 && issues.map((issue) => <li>{issue}</li>)}
        {/* {loaded && stakesAvailable < 500000 && <>{ogAvailable ?  */}
        {loaded && <>{ogAvailable ? 
          <Button 
            variant="contained"
            size="small"
            color="primary"
            onClick={stakeUsingOg}>Stake using my OG token
          </Button>
          :
          <>
            <TextField
                // variant="outlined"
                margin="normal"
                required
                // size="small"
                focused
                fullWidth
                name="txtamount"
                label="Wallet to Stake"
                type="text"
                id="txtamount"
                value={destinationAddress}
                helperText={!isValidWalletAddress(destinationAddress) && 'Invalid address'}
                onChange={event => setDestinationAddress (event.target.value)}
            />
            <TextField
                // variant="outlined"
                margin="normal"
                required
                // size="small"
                fullWidth
                name="txtamount"
                label="Amount to Stake"
                type="text"
                id="txtamount"
                value={stakeAmount}
                // helperText={!isValidWalletAddress(destinationAddress) && 'Invalid address'}
                onChange={event => setStakeAmount (event.target.value)}
            />
            <Button 
              variant="contained"
              size="small"
              color="primary"
              onClick={stakeForWallet}>Stake
            </Button>
            <Button 
              style={{marginTop: '10px'}}
              variant="outlined"
              size="small"
              color="primary"
              onClick={unStakeForWallet}>Un Stake
            </Button>
          </>}
        </>}
        {!loaded &&
        <Typography>Loading...</Typography>
        }

        </Grid>
          <a href="/test-node" target="_blank">
            <Typography style={{marginTop: '20px', textAlign: 'center'}}>Test a Node</Typography>
          </a>
      </div>
    </Container>
  );
}
