import React, { useState, useEffect } from 'react';
import { Fab, Button, Typography, Grid, Container, Box, TextField, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Paper } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { getBlockchainNodeUrl } from '../globals/const';
import { getWalletFromDB, storeWalletToDB } from '../globals/db';
import { WalletTokensTable } from '../components/WalletTokensTable';
import { createOrder } from '../globals/fiat_payment';
import { BuyToken } from '../components/BuyToken';
import { TransferToken } from '../components/TransferToken';
import { ReceiveToken } from '../components/ReceiveToken';
import { ExpandMore } from '@material-ui/icons';
import { signTransaction } from '../globals/utils';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  mainContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    flexGrow: 1,
  },
  row: {
    item: true,
    container: true,
    spacing: 1,
    justify: 'center',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
  fabRow: {},
  fundSelectorRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonRow: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-around',
  }
}));

export default function TrustnetScreen() {
  const classes = useStyles();
  const [wallet, setWallet] = useState({});
  const [incomingTrustScores, setIncomingTrustScores] = useState([])
  const [outgoingTrustScores, setOutgoingTrustScores] = useState([])
  const [isWalletActive, setIsWalletActive] = useState(false);

  const getTrustScores = async () => {
    if (!wallet.address) return
    let res = await axios.get(getBlockchainNodeUrl() + '/get-incoming-trustscores?dst_wallet_address=' + wallet.address)
    if (res.status === 200) {
      console.log(res.data.trust_score)
      setIncomingTrustScores(res.data.trust_score)
      setIsWalletActive(true)
    }

    res = await axios.get(getBlockchainNodeUrl() + '/get-outgoing-trustscores?src_wallet_address=' + wallet.address)
    if (res.status === 200) {
      console.log(res.data.trust_score)
      setOutgoingTrustScores(res.data.trust_score)
      setIsWalletActive(true)
    }
  }

  const setTrustScore = async () => {
    const destWallet = prompt('Enter wallet address:');
    let trustScore = prompt('Enter new trust score: ')

    if (trustScore < -100 || trustScore > 100) {
      alert('Trustscore should be from -100 to +100')
      return
    }

    if (wallet.address === destWallet) {
      alert('Cannot set trust score for self')
      return
    }

    if (!destWallet || trustScore == null) {
      alert('Invalid inputs')
    }

    trustScore *= 10000;  //decimals

    let res = await axios.post(
        getBlockchainNodeUrl() + '/update-trustscore',
        {
          "source_address": wallet.address,
          "destination_address": destWallet,
          "tscore": parseInt(trustScore)
        }
    );
    const transaction_data = res.data;
    transaction_data['transaction']['fee'] = 1000000;
    res = await signTransaction(transaction_data, wallet)
    if (!window.confirm(`Your are setting trust score for ${destWallet} to ${trustScore}. Are you sure to proceed?`)) return
    res = await axios.post(
        getBlockchainNodeUrl() + '/submit-transaction',
        res)
    console.log(res)
    if (res.status === 200 && res.data.response.valid) {
        alert('Transaction successfully sent to blockchain')
    }    
  }

  useEffect(() => {
    getWalletFromDB((wallet) => { if (wallet) setWallet(wallet) });

  }, []);

  useEffect(() => {
    getTrustScores()

  }, [wallet]);

  function TrustScoresTable({ scores, pidField }) {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 200 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Person Id</TableCell>
              <TableCell align="right">Score</TableCell>
              <TableCell align="right">Last Update</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scores.map((row) => (
              <TableRow
                key={row[pidField]}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row[pidField]}
                </TableCell>
                <TableCell align="right">{row.score}</TableCell>
                <TableCell align="right">{row.last_time}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Container maxWidth="xs">
      <div className={classes.mainContent}>
        <Grid
          container
          // spacing={1}
          justify="center"
          direction="column"
          style={{ marginTop: '10px' }}
        >
          {/* FundSelector */}
          <Grid container item>
            <Grid item xs>
              {isWalletActive &&
                <>
                  <Typography variant="subtitle1">Wallet Address:</Typography>
                  <TextField
                    fullWidth value={wallet.address} size='small'
                    onFocus={event => {
                      event.target.select();
                    }}
                  // onClick={() => {navigator.clipboard.writeText(wallet.address); alert('Address copied')}}
                  />
                  
                  {isWalletActive &&
                    <Box style={{width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    marginTop: '20px',}}>
                      <Button
                          variant="contained"
                          onClick={setTrustScore}
                          size="small"
                          color="primary"
                        >
                          Set Trust Score</Button>
                      <Typography variant="subtitle1" style={{marginTop: '10px'}}>Incoming Trust Scores:</Typography>
                      <TrustScoresTable scores={incomingTrustScores} pidField="src_person_id"/>

                      <Typography variant="subtitle1" style={{marginTop: '10px'}}>Outgoing Trust Scores:</Typography>
                      <TrustScoresTable scores={outgoingTrustScores} pidField="dest_person_id"/>

                    </Box>
                  }
                </>
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
