import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Copyright from '../components/Copyright';
import {useHistory, Link} from 'react-router-dom';
import {useState} from 'react';
import axios from 'axios';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const useStyles = makeStyles (theme => ({
  paper: {
    marginTop: theme.spacing (8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing (1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing (3),
  },
  submit: {
    margin: theme.spacing (3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function StableCoins () {
  const classes = useStyles ();
  const history = useHistory ();
  
  const [select, setSelect] = React.useState('ninr');
  const [nusdcAmount, setNusdcAmount] = React.useState('30')  
  const [ninrAmount, setNinrAmount] = React.useState('25000')
  const [creds, setCreds] = useState ({});


  function setCredField (key, value) {
    let _creds = creds;
    creds[key] = value;
    setCreds (_creds);
  }

  const handleChange = (event) => {
    event.preventDefault();
    setSelect(event.target.value);
    console.log(select)
  };

  function submitHandler (event) {
    event.preventDefault ();
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <MonetizationOn />
        </Avatar>
        <Typography component="h1" variant="h5">
          Stable Coins
        </Typography>

        <Box pt="2rem">
            <Grid container 
            display="flex"
            justifyContent="center"
            alignItems="center" xs={12}>
                <Box m={2}>
                <Typography variant="body1">
                    Select Coin
                </Typography>
                </Box>
             
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Coin</InputLabel>
                    <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={select}
                    onChange={handleChange}
                    label="NUSDC"
                    >
                    <MenuItem value={"nusdc"}>NUSDC</MenuItem>
                    <MenuItem value={"ninr"}>NINR</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Box>

        <Box pt="1.25rem">
            <Grid container 
            display="flex"
            justifyContent="center"
            alignItems="center" xs={12}>
                
                {
                select==='nusdc' ? 
                (   <>
                        <Box p={1}>
                            <Typography variant="h5">Newrl NUSDC Balance:</Typography>
                        </Box>
                        <Chip
                            p={4}
                            label={nusdcAmount}
                            color="primary"
                        />

                        <Box pt={1.5}>
                        <Grid container>
                            <Grid item>
                                {/* Button hrefs to be changed  */}
                                <Button href="http://localhost:3001/buy-nusdc" variant="contained" color="primary">
                                Buy more +
                                </Button>
                            </Grid>
                            <Box p={1}></Box>
                            <Grid item>
                                <Button variant="contained" color="primary">
                                Sell
                                </Button>
                            </Grid>
                        </Grid>

                        </Box>
                    </> 
                )
                : 
                (   <>
                        <Box p={1}>
                        <Typography variant="h5">Newrl NINR Balance:</Typography>
                        </Box>
                        <Chip
                            p={4}
                            label={ninrAmount}
                            color="primary"
                        />
                        <Box pt={1.5}>
                        <Grid container>
                            <Grid item>
                                {/* Button hrefs to be changed  */}
                                <Button href="http://localhost:3001/buy-ninr" variant="contained" color="primary">
                                Buy more +
                                </Button>
                            </Grid>
                            <Box p={1}></Box>
                            <Grid item>
                                <Button variant="contained" color="primary">
                                Sell
                                </Button>
                            </Grid>
                        </Grid>

                        </Box>   
                    </> 
                )
                }   
            </Grid>
        </Box>

        <Box pt="1.25rem">

                
            {/* <Grid container>
                <Grid item>
                    <Button href="http://localhost:3001/buy-ninr" variant="contained" color="primary">
                    Buy more+
                    </Button>
                </Grid>
                <Box p={1}></Box>
                <Grid item>
                    <Button variant="contained" color="primary">
                    Sell
                    </Button>
                </Grid>

            </Grid> */}

        </Box>

      </div>
      <Box mt={7}>
        <Copyright />
      </Box>
    </Container>
  );
}
