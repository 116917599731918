import React, { useState, useEffect } from 'react';
import { Fab, Button, Typography, Grid, Container, Box, TextField, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Paper } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { getBlockchainNodeUrl, getTransactionToSignFromLocal } from '../globals/const';
import { getWalletFromDB, storeWalletToDB } from '../globals/db';
import { WalletTokensTable } from '../components/WalletTokensTable';
import { createOrder } from '../globals/fiat_payment';
import { BuyToken } from '../components/BuyToken';
import { TransferToken } from '../components/TransferToken';
import { ReceiveToken } from '../components/ReceiveToken';
import { ExpandMore } from '@material-ui/icons';
import { signTransaction } from '../globals/utils';
import { useLocation } from "react-router-dom";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  mainContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    flexGrow: 1,
  },
  row: {
    item: true,
    container: true,
    spacing: 1,
    justify: 'center',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
  fabRow: {},
  fundSelectorRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonRow: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-around',
  }
}));


// function useQuery() {
//   const { search } = useLocation();

//   return React.useMemo(() => new URLSearchParams(search), [search]);
// }

export default function SignTransaction() {
  // let urlQuery = useQuery();
  // const txn = urlQuery.get('txn')
  const externalTransaction = getTransactionToSignFromLocal();

  const classes = useStyles();
  const [wallet, setWallet] = useState({});
  const [transaction, setTransaction] = useState(externalTransaction)
  const [signedTransaction, setSignedTransaction] = useState("")
  const [isWalletActive, setIsWalletActive] = useState(false);


  const setTrustScore = async () => {
    let trans;
    try {
      trans = JSON.parse(transaction)
    } catch(e) {
      alert('Transaction is not valid JSON')
      return
    }
    const walletRes = await axios.get(getBlockchainNodeUrl() + '/get-wallet?wallet_address=' + wallet.address)
    console.log(trans, wallet)
    const res = await signTransaction(trans, wallet)
    setSignedTransaction(JSON.stringify(res))
  }

  const submitTransaction = async () => {
    const transactionJSON = JSON.parse(signedTransaction);
    if (!window.confirm(`Are you sign and submit this transaction to Newrl?`)) return
    let res = await axios.post(
      getBlockchainNodeUrl() + '/submit-transaction',
      transactionJSON)
    console.log(res)
    if (res.status === 200 && res.data.response.valid) {
      alert('Transaction successfully sent to blockchain')
      localStorage.setItem('transactionToSign', '');
      if (externalTransaction !== null && externalTransaction !== '') {
        window.close()
      }
      
    } else {
      alert('Invalid transaction: ' + res.data?.response?.msg)
    }
  }

  useEffect(() => {
    getWalletFromDB((wallet) => { if (wallet) setWallet(wallet) });

  }, []);

  useEffect(() => {

  }, [wallet]);

  return (
    <Container maxWidth="xs">
      <div className={classes.mainContent}>
        <Grid
          container
          // spacing={1}
          justify="center"
          direction="column"
          style={{ marginTop: '10px' }}
        >
          {/* FundSelector */}
          <Grid container item>
            <Grid item xs>

              <Box style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                marginTop: '20px',
              }}>
                <Typography variant="subtitle1" style={{ marginTop: '10px' }}>Sign Transaction:</Typography>
                <TextField
                  placeholder="Enter you transaction JSON"
                  multiline
                  rows={6}
                  maxRows={8}
                  style={{ marginBottom: '10px' }}
                  onChange={(e) => setTransaction(e.target.value)}
                  value={transaction}
                />
                <Button
                  variant="contained"
                  onClick={setTrustScore}
                  size="small"
                  color="primary"
                >
                  Sign Transaction</Button>
                
                  <TextField
                  multiline
                  rows={6}
                  maxRows={8}
                  style={{ marginBottom: '10px' }}
                  disabled
                  value={signedTransaction}
                />

                <Button
                  variant="contained"
                  onClick={submitTransaction}
                  size="small"
                  color="primary"
                  disabled={signedTransaction === ""}
                >
                  Submit To Chain</Button>

              </Box>

            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
