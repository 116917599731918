import React, { useEffect, useState } from "react";
import Table from "./Table";
import axios from 'axios'
import { getBlockchainNodeUrl } from "../globals/const";
import { Box, Button, TextField, Typography, FormControl, InputLabel, MenuItem, Select, Container } from "@material-ui/core";
import { createOrder } from "../globals/fiat_payment";
import { getWalletFromDB } from "../globals/db";
import { useLocation } from "react-router-dom";
import { getDecimal } from '../globals/utils'

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function TransferToken({ balances }) {
    let urlQuery = useQuery();

    const [quantity, setQuantity] = useState();
    const [destinationAddress, setDestinationAddress] = useState(urlQuery.get('destination_address'));
    const [token, setToken] = useState('');
    const [wallet, setWallet] = useState({});
    const [maxQuantity, setMaxQuantity] = useState();
    const [walletBalances, setWalletBalances] = useState([]);
    
    useEffect(() => {
        console.log(balances)
        if (balances && balances.length > 0) {
            setToken(balances[0].token_code)
            setMaxQuantity(balances[0].balance)
            setWalletBalances(balances)
        }
    }, [balances])

    useEffect(() => {
        setMaxQuantity(walletBalances.find((b) => b.token_code === token)?.balance)
    }, [token])

    useEffect(() => {
        getWalletFromDB((wallet) => { if (wallet) setWallet(wallet) });
    
      }, []);
    
    useEffect(() => {
        if (!balances) {
            axios.get(getBlockchainNodeUrl() + '/get-balances?balance_type=ALL_TOKENS_IN_WALLET&wallet_address=' + wallet.address)
            .then(res => res.data.balance)
            .then(data => {
                console.log(data)
                setToken(data[0]?.token_code)
                setMaxQuantity(data[0]?.balance)
                setWalletBalances(data)
            })
        }
    }, [wallet, balances])

    const transferToken = async () => {
        if (!isValidWalletAddress(destinationAddress)) {
            alert('Invalid destination address')
            return
        }
        if (!maxQuantity || quantity > maxQuantity) {
            alert('Quantity exceeds max quantity')
            return
        }
        if (quantity === 0 || !quantity) {
            alert('Quantity should be non zero')
            return
        }
        if (destinationAddress === wallet.address) {
            alert('You cannot transfer to yourself')
            return
        }
        if (token in [null, undefined, '']) {
            alert('Invalid token')
            return
        }
        console.log(wallet, destinationAddress, quantity, token)
        const decimalAdjustedQty = quantity * getDecimal(token);
        let res = await axios.post(
            getBlockchainNodeUrl() + '/add-transfer',
            {
                transfer_type: 5,
                asset1_code: token,
                asset2_code: "",
                wallet1_address: wallet.address,
                wallet2_address: destinationAddress,
                asset1_qty: decimalAdjustedQty,
                asset2_qty: 0,
            }
        );
        const transaction_data = res.data;
        transaction_data['transaction']['fee'] = 1000000;
        res = await axios.post(
            getBlockchainNodeUrl() + '/sign-transaction',
            {
                wallet_data: wallet,
                transaction_data: res.data
            })
        if (!window.confirm(`Your wallet will be debited with ${quantity} ${token} token(s). Are you sure to proceed?`)) return
        res = await axios.post(
            getBlockchainNodeUrl() + '/submit-transaction',
            res.data)
        console.log(res)
        if (res.status === 200 && res.data.response.valid) {
            alert('Transaction successfully sent to blockchain')
            setDestinationAddress('')
            setQuantity(0)
        }
    }

    const isValidWalletAddress = (address) => {
        return true; // disabling validation due to sc addresses
        // return /^0x([a-f0-9]){40,40}$/.test(address)
    }

    return (
        <Container maxWidth="xs">
        <Box style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            marginTop: '20px',
            // marginLeft: '10%', marginRight: '10%', 
            }}>
            <Typography variant="h6">Send Token</Typography>
            <FormControl fullWidth>
                <InputLabel id="token-select-label">Token to send</InputLabel>
                <Select
                    labelId="token-select-label"
                    id="token-select"
                    value={token}
                    label="Token to send"
                    onChange={(e) => {
                        setToken(e.target.value)
                        
                    }}
                >
                    {walletBalances.map(b => <MenuItem value={b.token_code}>{b.token_code}</MenuItem>)}
                </Select>
            </FormControl>
            <TextField
                // variant="outlined"
                margin="normal"
                required
                // size="small"
                fullWidth
                name="txtamount"
                label="Destination Wallet Address"
                type="numeric"
                id="txtamount"
                value={destinationAddress}
                helperText={!isValidWalletAddress(destinationAddress) && 'Invalid address'}
                onChange={event => setDestinationAddress (event.target.value)}
            />
            <TextField
                // variant="outlined"
                margin="normal"
                required
                // size="small"
                fullWidth
                name="txtamount"
                label="Quantity"
                helperText={maxQuantity && ('Max quantity ' + maxQuantity)}
                type="numeric"
                id="txtamount"
                value={quantity}
                onChange={event => setQuantity (event.target.value)}
            />
            <Button
                variant="contained"
                onClick={() => transferToken()}
                // size="small"
                color="primary"
                fullWidth
                >
                Transfer
            </Button>
        </Box>
        </Container>
    )
}