import React, { useEffect, useState } from "react";
import Table from "./Table";
import axios from 'axios'
import { Box, Button, TextField, Typography, FormControl, InputLabel, MenuItem, Select, Container } from "@material-ui/core";
import { createOrder } from "../globals/fiat_payment";
import { getWalletFromDB } from "../globals/db";
import { useLocation } from "react-router-dom";
import sha256 from 'crypto-js/sha256';
import CryptoJS from "crypto-js";
import { getBlockchain } from "../globals/const";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function ActivateWallet({ wallet, setWalletAdditionRequestSent }) {
    let urlQuery = useQuery();

    const [quantity, setQuantity] = useState();
    const [destinationAddress, setDestinationAddress] = useState(urlQuery.get('destination_address'));
    const [ownertype, setOwnerType] = useState('');
    const [jurisdiction, setJurisdiction] = useState('');
    const [kyc_doc_type, setKycDocType] = useState('');
    const [kyc_doc_hash, setKycDocHash] = useState('');
    // const [wallet, setWallet] = useState({});
    const [maxQuantity, setMaxQuantity] = useState();
    const [requesExists, setRequesExists] = useState(false);

    useEffect(() => {
        // getWalletFromDB((wallet) => { if (wallet) setWallet(wallet) });

        axios.get('https://app.newrl.net/api/kyc/kyc-request-exists?public_key=' + wallet.public)
        .then((res) => setRequesExists(res.data.kyc_details_count > 0))
    
      }, []);
    
      function arrayBufferToWordArray(ab) {
        var i8a = new Uint8Array(ab);
        var a = [];
        for (var i = 0; i < i8a.length; i += 4) {
          a.push(i8a[i] << 24 | i8a[i + 1] << 16 | i8a[i + 2] << 8 | i8a[i + 3]);
        }
        return CryptoJS.lib.WordArray.create(a, i8a.length);
      }
      
      function handleFileSelect(evt) {
        var files = evt.target.files; // FileList object
      
        // Loop through the FileList and render image files as thumbnails.
        for (var i = 0, f; f = files[i]; i++) {
          var reader = new FileReader();
      
          // Closure to capture the file information.
          reader.onloadend = (function(theFile) {
            return function(e) {
              var arrayBuffer = e.target.result;
      
              var hash = CryptoJS.SHA256(arrayBufferToWordArray(arrayBuffer));
              setKycDocHash(hash)
            //   var elem = document.getElementById("hashValue");
            //   elem.value = hash;
            };
      
          })(f);
          reader.onerror = function(e) {
            console.error(e);
          };
      
          // Read in the image file as a data URL.
          reader.readAsArrayBuffer(f);
        }
      }
    
    // useEffect(() => {
    //     if (!balances) {
    //         axios.get(getBlockchainNodeUrl() + '/get-balances?balance_type=ALL_TOKENS_IN_WALLET&wallet_address=' + wallet.address)
    //         .then(res => res.data.balance)
    //         .then(data => {
    //             console.log(data)
    //             // setToken(data[0]?.token_code)
    //             setMaxQuantity(data[0]?.balance)
    //             setWalletBalances(data)
    //         })
    //     }
    // }, [wallet, balances])


    const isValidWalletAddress = (address) => {
        return /^0x([a-f0-9]){40,40}$/.test(address)
    }

    async function activateWallet() {
        // if ([1].includes(ownertype)) {
        //     if (![1, 2, 3, 4, 5].includes(kyc_doc_type)) {
        //         alert('Invalid doc type for natural person')
        //         return
        //     }
        // } else {
        //     if (![11, 12].includes(kyc_doc_type)) {
        //         alert('Invalid doc type for person type')
        //         return
        //     }
        // }
        // if (!kyc_doc_hash || kyc_doc_hash.length === 0) {
        //     alert('No KYC doc selected')
        //     return
        // }
        let url = `https://fcme3n27ha.execute-api.ap-south-1.amazonaws.com/default/NewrlDevnetAddWallet?public_key=${wallet.public}&ownertype=1&jurisdiction=IN&kyc_doc_type=2&kyc_doc_hash=test`
        // let url = 'https://fcme3n27ha.execute-api.ap-south-1.amazonaws.com/default/NewrlDevnetAddWallet?'
        // url += 'public_key=' + encodeURIComponent(wallet.public);
        // url += '&ownertype=' + ownertype;
        // url += '&jurisdiction=' + jurisdiction;
        // url += '&kyc_doc_type=' + kyc_doc_type;
        // url += '&kyc_doc_hash=' + kyc_doc_hash;
        // window.open(url)
        axios.get(url)
        .then((res) => {
            if (res.status === 200) {
                alert('Wallet activation request sent.')
                setWalletAdditionRequestSent(true)
            } else {
                alert('Could not send wallet activation request')
            }
        })
        .catch(() => {
            alert('Could not send wallet activation request')
        })
      }

    return (
        <Container maxWidth="xs">
        <Box style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            marginTop: '20px',
            // marginLeft: '10%', marginRight: '10%', 
            }}>
            <Typography variant="h6">Activate Wallet</Typography>
            <Typography>
                Your wallet need to be verified and added to the blockchain by an existing custodian. 
            </Typography>
            {/* <FormControl fullWidth>
                <InputLabel id="token-select-label">Person Type</InputLabel>
                <Select
                    labelId="select-person-type-label"
                    id="select-person-type"
                    // value={token}
                    label="Person Type"
                    onChange={(e) => {
                        setOwnerType(e.target.value)
                        
                    }}
                >
                    <MenuItem value={1}>Natural Person</MenuItem>
                    <MenuItem value={2}>Private Company</MenuItem>
                    <MenuItem value={5}>LLP</MenuItem>
                    <MenuItem value={6}>Trust</MenuItem>
                    <MenuItem value={9}>DAO on Newrl</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="token-select-label">Jurisdiction</InputLabel>
                <Select
                    labelId="select-person-type-label"
                    id="select-person-type"
                    // value={token}
                    label="Jurisdiction"
                    onChange={(e) => {
                        setJurisdiction(e.target.value)
                        
                    }}
                >
                    <MenuItem value={101}>USA</MenuItem>
                    <MenuItem value={910}>India</MenuItem>
                    <MenuItem value={620}>Indonesia</MenuItem>
                    <MenuItem value={650}>Singapore</MenuItem>
                    <MenuItem value={971}>UAE</MenuItem>
                    <MenuItem value={440}>UK</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="token-select-label">Document Type</InputLabel>
                <Select
                    labelId="select-person-type-label"
                    id="select-person-type"
                    // value={token}
                    label="Document Type"
                    onChange={(e) => {
                        setKycDocType(e.target.value)
                        
                    }}
                >
                    <MenuItem value={1}>Tax Id</MenuItem>
                    <MenuItem value={2}>Social Security ID</MenuItem>
                    <MenuItem value={3}>Passport</MenuItem>
                    <MenuItem value={4}>Driving Licence</MenuItem>
                    <MenuItem value={5}>Birth Certificate</MenuItem>
                    <MenuItem value={11}>Incorporation certificate</MenuItem>
                    <MenuItem value={12}>Corporate tax certificate</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                KYC File: <input type="file" onChange={handleFileSelect}/>

            </FormControl> */}
            
            {requesExists? <Typography variant="subtitle1" justifyContent="center">Wallet activation request already sent. Please wait for approval.</Typography>
            :<Button
                variant="contained"
                // onClick={() => activateWallet()}
                onClick={() => {
                    if (getBlockchain() === 'Mainnet') {
                        window.open('https://app.newrl.net/request-new-wallet?public_key='+wallet.public)
                    } else {
                        activateWallet()
                    }
                }}
                style={{marginTop: '20px'}}
                // size="small"
                color="primary"
                fullWidth
                >
                Activate Wallet
            </Button>}
        </Box>
        </Container>
    )
}