import sha256 from "crypto-js/sha256";

export function isAuthenticated(){ 
  let p = sha256(getPassWord()).toString();
  let p2 = getPasswordLocalStorage();
  console.log(p, p2)
    if(sha256(getPassWord()).toString() === getPasswordLocalStorage()){
      return true;
    }
    return false;
}

export function getPassWord(){ 
    return encodeURI(getCookie('pass'));
}

export function setPasswordCookie(pass){
    setCookie('pass',pass,5);
}

export function isValidPassword(password) {
  return getPasswordLocalStorage() === sha256(password).toString()
}

export function logOut(){
  deleteCookie('pass');
}


function setCookie(cname, cvalue, hour) {
    const d = new Date();
    d.setTime(d.getTime() + (5*hour*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function deleteCookie(name) {
  document.cookie = '';
  // document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function setPasswordLocalStorage(pass) {
  console.log('settinglocalpss')
  const encPass = sha256(pass).toString();
  localStorage.setItem('encPass', encPass);
}

export function getPasswordLocalStorage() {
  return localStorage.getItem('encPass')
}

export function clearPasswordLocalStorage() {
  return localStorage.removeItem('encPass')
}