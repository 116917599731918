import React, { useState, useEffect } from 'react';
import { Fab, Button, Typography, Grid, Container, Box, TextField, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { getWalletFromDB, storeWalletToDB } from '../globals/db';
import { WalletTokensTable } from '../components/WalletTokensTable';
import { createOrder } from '../globals/fiat_payment';
import { BuyToken } from '../components/BuyToken';
import { TransferToken } from '../components/TransferToken';
import { ReceiveToken } from '../components/ReceiveToken';
import { ExpandMore } from '@material-ui/icons';
import { ActivateWallet } from '../components/ActivateWallet';
// const { randomBytes } = require('crypto')
// const secp256k1 = require('secp256k1')

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  mainContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    flexGrow: 1,
  },
  row: {
    item: true,
    container: true,
    spacing: 1,
    justify: 'center',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
  fabRow: {},
  fundSelectorRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonRow: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-around',
  }
}));

export default function ComingSoon() {
  const classes = useStyles();
  

  return (
    <Container maxWidth="xs">
      <div className={classes.mainContent}>
        <Grid
          container
          // spacing={1}
          justify="center"
          direction="column"
          style={{ marginTop: '10px' }}
        >
         <Typography>Coming soon!</Typography>
        </Grid>
      </div>
    </Container>
  );
}
