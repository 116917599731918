import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { isLoggedIn } from "../globals/utils";
import Copyright from "../components/Copyright";

import {
  getPassWordFromDB,
  storePassWordToDB,
  clearDBData,
} from "../globals/db";

import {
  clearPasswordLocalStorage,
  getPasswordLocalStorage,
  isValidPassword,
  setPasswordCookie,
  setPasswordLocalStorage,
} from "../globals/authentication";
import { HOME_PAGE } from "../globals/const";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  row: {
    marginTop: theme.spacing(1),
  },
}));

export default function Login() {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const history = useHistory();

  const isNewUser = getPasswordLocalStorage() === null;

  function signUp(event) {
    event.preventDefault();

    let val = encodeURI(password);
    console.log(val);
    setPasswordLocalStorage(val);
    setPasswordCookie(password);
    history.push(".");
  }

  function login(event) {
    event.preventDefault();
    let val = encodeURI(password);
    if (isValidPassword(val)) {
      setPasswordCookie(password);
      let next = history.location.pathname + history.location.search;
      next = next.replace("next=/login?", "");
      next = next.replace("?next=", "");
      next = next.replace("login", "");
      next = next.replace("//", "/");
      console.log(next);
      // next = next.replace("/login?next=", "");
      // window.location.href = (next);
      history.push(HOME_PAGE + next);
    } else {
      alert("Incorrect password");
    }
  }

  const resetPassword = () => {
    if (
      window.confirm(
        "Forget password will reset the application. You will have to import the wallet again. Sure to reset?"
      )
    ) {
      clearDBData();
      clearPasswordLocalStorage();
      // window.location = "/";
      window.location = "/";
    }
  };

  
  useEffect(() => {
    if (isLoggedIn()) {
      let next = history.location.pathname + history.location.search;
      next = next.replace("next=/login?", "");
      next = next.replace("/login", "/");
      // console.log(next)
      // alert(next)
      history.push(next);
    }
  }, [])

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}

        <img
          alt="asqi"
          src={HOME_PAGE + "/newrl_logo.png"}
          width={200}
          height={200}
        />
        <Typography component="h1" variant="h5" style={{ marginTop: 20 }}>
          Newrl Wallet
        </Typography>
        {/* <Grid className={classes.row}>
          <GoogleLogin
            clientId={GOOGLE_OAUTH_CLIENT_ID}
            buttonText="Login/Register with Google Account"
            onSuccess={onGoogleOAuthResponse}
            onFailure={onGoogleOAuthResponse}
            cookiePolicy={'single_host_origin'}
          />
        </Grid> */}
        <form
          className={classes.form}
          noValidate
          onSubmit={isNewUser ? signUp : login}
        >
          {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={event => setEmail (event.target.value)}
          /> */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={isNewUser ? "Enter new password" : "Password"}
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(event) => setPassword(event.target.value)}
          />
          {isNewUser && <Typography>This is a new password which will be needed to unlock your wallet. It's important to keep it safe and remember it.</Typography>}
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Login
          </Button>
          <Grid container>
            <Grid item xs>
              <a href="#" onClick={resetPassword}>
                <Typography variant="body2">Forgot password?</Typography>
              </a>
            </Grid>
            {/* <Grid item>
              <Link to="signup">{"Don't have a wallet? Sign Up"}</Link>
            </Grid> */}
          </Grid>
        </form>
      </div>


      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
