import React, { useEffect, useState } from "react";
import Table from "./Table";
import axios from 'axios'
import { getBlockchainNodeUrl } from "../globals/const";
import { getDecimal } from "../globals/utils";
import { Box, Typography } from "@material-ui/core";

export function WalletTokensTable({walletAddress, setWalletBalancesParent}) {
    console.log('getting tokens for', walletAddress)
    // let walletAddress = '0xde7d58078c683c7fddae17ad541f01e2429de1c6'
    const [walletBalances, setWalletBalances] = useState([])

    const columns = [
        {
            Header: "Token",
            accessor: "token_code"
        },
        {
            Header: "Balance",
            accessor: "balance"
        }
    ];

    useEffect(() => {
        axios.get(getBlockchainNodeUrl() + '/get-balances?balance_type=ALL_TOKENS_IN_WALLET&wallet_address=' + walletAddress)
            .then(res => res.data.balance)
            .then((data) => data.map((d) => {return {...d, balance: d['balance'] / getDecimal(d['token_code'])}}))
            .then(data => {
                setWalletBalances(data)
                setWalletBalancesParent(data)
            })
    }, [walletAddress])

    return (
        <Box style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContents: 'center',
            marginTop: '10px',
            marginBottom: '10px',
        }}>
            {walletBalances.length === 0 ?
                <Box style={{display: 'flex', justifyContent: 'center'}}>
                    <Typography>No Tokens available in wallet</Typography>
                </Box>
                :
                <Table
                    columns={columns}
                    data={walletBalances}
                    click={() => { }}
                />}
        </Box>
    )
}