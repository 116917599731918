import { NEWRL_PAY_SERVICE } from "./const";
import axios from 'axios';
import { getBlockchainNodeUrl } from './const';
import { ethers } from "ethers";

export async function verifyPayment(response) {
    console.log(response);
    const rawResponse = await fetch(NEWRL_PAY_SERVICE + '/verify-payment', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(response)
    });
    const content = await rawResponse.json();
    alert("Transaction submitted to blockchain. Please check your wallet after a minute.");
    console.log(content);
  }

export async function createOrder(walletAddress, amount) {
    if (!walletAddress) alert('Please create and activate a Newrl wallet to purchase')
    // const amount = 100;
    const currency = 'INR';
    const address = walletAddress;
    // alert("Transaction added to Newrl mempool. Please wait a minute for it to be added to a block by a miner. You can check the wallet balance with this API. Token code is NINR");
    try {
      fetch(`${NEWRL_PAY_SERVICE}/create-order?amount=${amount}&currency=${currency}&address=${walletAddress}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          triggerPayment(res.order_id)
        });
    } catch (e) {
      console.log(e)
    }
    
  }

 export function triggerPayment(_orderId) {
    const RZP_KEY = 'rzp_test_lIPFFCrCh8ljm1';
    const orderId = _orderId;

    var options = {
      key: RZP_KEY,
      currency: "INR",
      name: "Newrl Pay",
      description: "Buying NINR tokens on Newrl",
      image: "https://newrl.net/img/logo.png",
      order_id: orderId,
      handler: function (response) {
        verifyPayment(response);
      },
      notes: {

      },
      // prefill: result.prefill,
      // theme: {
      //     "color": ASQI_COLOR
      // }
    };
    let rzp = new window.Razorpay(options);
    rzp.open();
}

export async function offRampNUSDC(wallet, ethereumWalletAddress, decimalAdjustedQty) {
  const NEWRL_CUSTODIAN_ADDRESS = '0x835b67ef940b6da344dd5af7fe5dd165a3c736d2';
  let res = await axios.post(
    getBlockchainNodeUrl() + '/add-transfer',
    {
      transfer_type: 5,
      asset1_code: 'NUSDC',
      asset2_code: '',
      wallet1_address: wallet.address,
      wallet2_address: NEWRL_CUSTODIAN_ADDRESS,
      asset1_qty: decimalAdjustedQty,
      asset2_qty: 0,
      additional_data: {
        'ethWallet': ethereumWalletAddress
      }
    }
  );
  const transaction_data = res.data;
  transaction_data['transaction']['fee'] = 1000000;
  res = await axios.post(
      getBlockchainNodeUrl() + '/sign-transaction',
      {
          wallet_data: wallet,
          transaction_data: res.data
      })
  // console.log(res.data)
  if (!window.confirm(`Your wallet will be debited with ${decimalAdjustedQty} NUSDC token(s). Are you sure to proceed?`)) return

  
  // res = await axios.post(
  //     getBlockchainNodeUrl() + '/submit-transaction',
  //     res.data)
  // console.log(res)
  // if (res.status === 200 && res.data.response.valid) {
  //     alert('Transaction successfully sent to blockchain')
  //     setDestinationAddress('')
  //     setQuantity(0)
  // }
}

export const getEthWallet = async () => {
  let ethWallet = window?.ethereum?.selectedAddress;
  if (!ethWallet) {
    alert('MetaMask or equivalent wallet not installed. Could not get your Ethereum wallet address. Please enter manually if you are sure.')
    ethWallet = prompt('Enter your Ethereum wallet address:');
  }
  return ethWallet;
}

export const triggerNUSDCBuy = async (newrlWallet, amount) => {
  // const web3 = window.web3;
  if (window.ethereum === undefined) {
    alert('MetaMask or equivalent wallet not installed. This is necessary for transfer of USDC.')
    return;
  }

  // let tokenAddress = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174";  # ETH bridged
  let tokenAddress = "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359";  // Native Polygon
  let toAddress = "0x40966c9ffE758787552cB6DA65182E4535ABd1cc";
  // Use BigNumber
  // let decimals = Web3Utils.toBN(6);
  // let amount = Web3Utils.toBN(5);
  let minABI = [
  // transfer
  {
      "constant": false,
      "inputs": [
      {
          "name": "_to",
          "type": "address"
      },
      {
          "name": "_value",
          "type": "uint256"
      }
      ],
      "name": "transfer",
      "outputs": [
      {
          "name": "",
          "type": "bool"
      }
      ],
      "type": "function"
  }
  ];
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const { chainId } = await provider.getNetwork()
  console.log(chainId) // 42
  if (chainId !== 137) {
    window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [{
            chainId: "0x89",
            // rpcUrls: ["https://polygon.llamarpc.com"],
            rpcUrls: ["https://polygon-rpc.com/"],
            chainName: "Polygon Mainnet",
            nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18
            },
            blockExplorerUrls: ["https://polygonscan.com/"]
        }]
    });
  }

  // MetaMask requires requesting permission to connect users accounts
  await provider.send("eth_requestAccounts", []);

  // The MetaMask plugin also allows signing transactions to
  // send ether and pay to change state within the blockchain.
  // For this, you need the account signer...
  const signer = provider.getSigner()
  const contract = new ethers.Contract(tokenAddress, minABI, provider);
  const daiWithSigner = contract.connect(signer);

  const dai = ethers.utils.parseUnits(amount, 6);

  // Send 1 DAI to "ricmoo.firefly.eth"
  try {
    const tx = await daiWithSigner.transfer(toAddress, dai);
    console.log(tx)
    axios.post(`${NEWRL_PAY_SERVICE}/nusdcapp/buy?newrl_wallet=${newrlWallet}&txhash=${tx.hash}`)
    alert('Transaction submitted. Check your wallet balance in few seconds.')
  } catch (err) {
    alert('Failed to send transaction to Polygon.' + err)
  }
  // if (typeof window.web3 !== 'undefined') {
  //     console.log(window.web3)
  //     // web3 = new Web3(web3.currentProvider);
      
  // }
}