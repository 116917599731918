import React from 'react';
// import logo from './logo.svg';
import './App.css';
import AppScreen from './screens/AppScreen';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const THEME = createMuiTheme({
  typography: {
   "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
  //  "fontSize": 14,
  //  "fontWeightLight": 300,
  //  "fontWeightRegular": 400,
  //  "fontWeightMedium": 500
  },
  palette: {
    primary: {
      // main: '#0e4476'
      main: '#253D72'
    },
    secondary: {
      main: '#9E9E9E'
    }
  }
});


// For the plugin to work kindly add a file .env.production
//  .env.production
//   > INLINE_RUNTIME_CHUNK=false ( Content of the file)


function App() {
  return(
    <ThemeProvider theme={THEME}>
      <AppScreen />
    </ThemeProvider>
  );
}

export default App;
