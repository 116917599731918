import React, { useState, useEffect } from 'react';
import { Fab, Button, Typography, Grid, Container, Box, TextField, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Paper } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { getBlockchainPort, FAUCET_URL, NWRL_DECIMAL_MULTIPLIER, getBlockchainScanner } from '../globals/const';
import { isValidWalletAddress } from '../globals/utils';
import { getWalletFromDB, storeWalletToDB } from '../globals/db';
import { WalletTokensTable } from '../components/WalletTokensTable';
import { createOrder } from '../globals/fiat_payment';
import { BuyToken } from '../components/BuyToken';
import { TransferToken } from '../components/TransferToken';
import { ReceiveToken } from '../components/ReceiveToken';
import { ExpandMore } from '@material-ui/icons';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  mainContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    flexGrow: 1,
  },
  row: {
    item: true,
    container: true,
    spacing: 1,
    justify: 'center',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
  fabRow: {},
  fundSelectorRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonRow: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-around',
  }
}));

export default function NodeTester() {
  const classes = useStyles();
  const [ip, setIP] = useState("")

  const testIP = async () => {
    if (!validateIPaddress(ip)) {
      alert('Invalid node IP')
      return
    }
    axios.get(`${getBlockchainScanner()}/test-node?nodeIP=${ip}`,{timeout: 5000})
    .then((res) => {
      if (res.status === 200) {
        alert('Node is RUNNING and port accessible')
      } else {
        alert('Node port is accessible but there is some issue with the node')
      }
    })
    .catch((err) => {
      alert(`Node is NOT running or port ${getBlockchainPort()} is NOT open`)
    })
  }

  const validateIPaddress = (ipaddress) => {  
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {  
      return (true)  
    }
    return (false)  
  }  

  return (
    <Container maxWidth="xs">
      <div className={classes.mainContent}>
        <Grid
          container
          // spacing={1}
          justify="center"
          direction="column"
          style={{ marginTop: '10px' }}
        >
          {/* FundSelector */}
          <Grid container item>
            <Grid item xs>

              <Box style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                marginTop: '20px',
              }}>
                <Typography variant="h4" style={{ marginTop: '10px' }}>Node Tester</Typography>
                <TextField
                    // variant="outlined"
                    margin="normal"
                    required
                    // size="small"
                    fullWidth
                    name="txtamount"
                    label="Node IP Address"
                    id="txtnodeip"
                    value={ip}
                    helperText={!validateIPaddress(ip) && 'Invalid IP'}
                    onChange={event => setIP (event.target.value)}
                />
                <Button
                  variant="contained"
                  onClick={testIP}
                  size="small"
                  color="primary"
                >
                  Check</Button>

                <Typography variant='caption' style={{marginTop: '10px'}}>Please retry if the first check fails. The node could be busy processing block or transactions at times.</Typography>

              </Box>

            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
